/* eslint-disable max-len */
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { LoadingView } from 'src/components/loading-view/LoadingView';
import { TripInformation } from 'src/screens/trips/TripInformation';
import { TripTabContent } from 'src/screens/trips/TripTabContent';
import { usePage } from 'src/components/page/Page';
import { useGetTrip } from 'src/hooks/getTrips';
import { Alert, Typography } from 'antd';
import moment from 'moment';
import { PBACContainer } from 'src/components/RBAC/RBACContainer';
import { PERMISSIONS } from 'src/types/user.type';

export const TripDetails = () => {
  const { id } = useParams();
  const { setSubTitle, setTitle } = usePage();
  const [{ data, loading }, refetch] = useGetTrip({ params: { tripId: parseInt(id!, 10) } });

  useEffect(() => {
    setTitle('Trips');
    setSubTitle('Trip information');
  }, []);

  const onCloseTrip = async () => {
    await refetch({ params: { tripId: parseInt(id!, 10) } });
  };

  const onOpenTrip = async () => {
    await refetch({ params: { tripId: parseInt(id!, 10) } });
  };

  if (loading || !data) {
    return <LoadingView />;
  }

  return (
    <div>
      <PBACContainer permissions={[PERMISSIONS.ReadTrip, PERMISSIONS.WriteTrip]}>
        <div style={{ marginBottom: 15 }}>
          {data.closedAt && (
            <Alert
              message={
                <Typography.Text>
                  This trip was closed on{' '}
                  <Typography.Text strong>{moment(data.closedAt).format('ddd, Do MMM YYYY')}</Typography.Text>. Actions on
                  this trip are limited, contact admin for futher information.
                </Typography.Text>
              }
              type="error"
            />
          )}
        </div>
        <TripInformation trip={data} onCloseTrip={onCloseTrip} onOpenTrip={onOpenTrip} />
        <TripTabContent trip={data} />
      </PBACContainer>

    </div>
  );
};
