/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */

import { FC, ReactNode } from 'react';
import { GiSteeringWheel } from 'react-icons/gi';
import { TripSeatMapSeat } from 'src/graphql/queries/get-trip-seatMap';
import { Dropdown } from 'antd';
import capitalize from 'lodash/capitalize';

import 'src/components/seat/styles/seatSelector.styles.less';
import { SeatColorSheme, statusActionsMap } from 'src/components/seat/seatSelectorUtils';
import { useUpdateTripSeatsStatus } from 'src/graphql/mutations/update-trip-seat-status';
import { SeatStatusEnum } from 'src/graphql/generated/types';
import { useAuth } from 'src/providers/auth-provider/AuthProvider';
import { PERMISSIONS } from 'src/types/user.type';

export interface SeatSelectorProps {
  seatMap: (TripSeatMapSeat | undefined)[][];
}

export interface SeatRowProps {
  rowIndex: number;
  row: (TripSeatMapSeat | undefined)[];
}

export interface SeatBoxProps {
  seat?: TripSeatMapSeat;
  icon?: ReactNode;
  status?: SeatStatusEnum | undefined;
}

type ColorSchemeKey = keyof typeof SeatColorSheme;

export const SeatBox: FC<SeatBoxProps> = ({ seat, icon, status }) => {
  const { user: authUser } = useAuth();
  const [updateTripSeatStatus, { loading: updateTripSeatStatusLoading }] = useUpdateTripSeatsStatus();
  const background = status ? SeatColorSheme[status] : undefined;
  const disabled = !seat ||
  [SeatStatusEnum.Blocked, SeatStatusEnum.Reserved, SeatStatusEnum.Occupied].includes(status!);

  const hasWriteSeatPermission = authUser?.permissions?.includes(PERMISSIONS.WriteSeat);

  const tripSeatStatus = async (seatId: number, seatStatus: SeatStatusEnum) => {
    await updateTripSeatStatus({
      variables: { seatId, status: seatStatus },
    });
  };

  const renderButtons = () => {
    return statusActionsMap(status!).map((action) => (
      <button
        key={action}
        type="button"
        disabled={updateTripSeatStatusLoading || !hasWriteSeatPermission}
        onClick={() => seat && tripSeatStatus(parseInt(seat.id, 10), action)}
      >
        {capitalize(action)}
      </button>
    ));
  };

  return (
    <Dropdown
      menu={{
        items: [
          {
            label: <div className="seat-map-dropdown-container">{renderButtons()}</div>,
            key: '0',
          },
        ],
      }}
      overlayClassName="seatbox-dropdown"
      trigger={['click']}
      disabled={!seat?.number || !hasWriteSeatPermission}
    >
      <button
        disabled={disabled || !hasWriteSeatPermission}
        className="seat-box"
        type="button"
        style={{ backgroundColor: seat?.number ? background : 'white', cursor: seat?.number ? 'pointer' : 'default' }}
      >
        {icon ?? seat?.number ?? ''}
      </button>
    </Dropdown>
  );
};

export const SeatRoW: FC<SeatRowProps> = ({ row, rowIndex }) => {
  return (
    <div className={row.length === 5 ? 'seat-row-5' : 'seat-row'}>
      {row.map((r, i) => {
        let icon;
        if (rowIndex === 0 && i === 0) icon = <GiSteeringWheel className="steering" />;
        return <SeatBox key={`${i}_box`} seat={r} icon={icon} status={r?.status} />;
      })}
    </div>
  );
};

export const SeatSelector: FC<SeatSelectorProps> = ({ seatMap }) => {
  return (
    <div className="seat-selector">
      <div>
        {seatMap.map((row, i) => (
          <SeatRoW rowIndex={i} key={`${i}_seat_row`} row={row} />
        ))}
      </div>
      <div className="seat-selector-color-display-container">
        {(Object.keys(SeatColorSheme) as ColorSchemeKey[]).map((key: ColorSchemeKey) => (
          <div key={key} className="seat-display-color-box-main">
            <div style={{ backgroundColor: SeatColorSheme[key] }} className="seat-display-color-box" />
            <p>{key}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
