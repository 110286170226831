import { ApolloQueryResult } from '@apollo/client';
import { Button, Checkbox, Col, Form, Row } from 'antd';
import { useParams } from 'react-router-dom';
import { Exact } from 'src/graphql/generated/types';
import { useUpdateUser } from 'src/graphql/mutations/update-user';
import { GetUserQuery } from 'src/graphql/queries/__generated__/get-user.generated';
import { useEffect } from 'react';
import { PBACContainer } from 'src/components/RBAC/RBACContainer';
import { PERMISSIONS } from 'src/types/user.type';

import { RolesDataQuery } from 'src/graphql/queries/__generated__/get-roles.generated';
import { useAuth } from 'src/providers/auth-provider/AuthProvider';

export const RolesForm = ({
  roles,
  refetch,
  systemRoles,
}: {
  roles: string[];
  systemRoles: RolesDataQuery | undefined;
  refetch: (
    variables?:
      | Partial<
        Exact<{
          userId: string;
        }>
      >
      | undefined,
  ) => Promise<ApolloQueryResult<GetUserQuery>>;
}) => {
  const [form] = Form.useForm();

  const { id } = useParams();
  const { user } = useAuth();

  const [updateUser, { data, loading: updateUserLoading }] = useUpdateUser();

  useEffect(() => {
    if (data) {
      refetch();
    }
  }, [data]);

  const handleSubmit = async () => {
    const values = await form.validateFields();

    await updateUser({
      variables: {
        input: {
          roles: values.checkboxGroup,
        },
        userId: id!,
      },
    });
  };

  return (
    <div>
      <Form autoComplete="on" form={form} initialValues={{ checkboxGroup: roles }}>
        <Form.Item
          name="checkboxGroup"
          label="Roles"
          rules={[
            {
              required: true,
              message: 'Please select at least one option!',
            },
          ]}
        >
          <Checkbox.Group style={{ width: '100%' }}>
            <Row gutter={[16, 16]}>
              {systemRoles?.rolesData.map((item) => (
                <Col key={item.value} xs={12} sm={8} md={8} lg={4} xl={4}>
                  <Checkbox
                    value={item.value}
                    // eslint-disable-next-line max-len
                    disabled={user?.permissions?.includes(PERMISSIONS.WriteUser)}
                  >
                    {item.key}
                  </Checkbox>
                </Col>
              ))}
            </Row>
          </Checkbox.Group>
        </Form.Item>

        <PBACContainer permissions={[PERMISSIONS.WriteUser]}>
          <Button key="submit" type="primary" onClick={handleSubmit} disabled={updateUserLoading}>
            Save
          </Button>
        </PBACContainer>
      </Form>
    </div>
  );
};
