import React, { PropsWithChildren } from 'react';
import { HasAccess } from '@permify/react-role';
import { PERMISSIONS } from 'src/types/user.type';
import { UnauthorizedPage } from 'src/components/error-pages/UnauthorizedPages';

export interface PBACProps extends PropsWithChildren {
  permissions?: PERMISSIONS[];
  children: React.ReactNode | any;
  renderFailed?: boolean;
}

export const PBACContainer: React.FC<PBACProps> = ({ permissions = [], children, renderFailed }) => {
  return (
    <HasAccess permissions={permissions} renderAuthFailed={renderFailed ? <UnauthorizedPage /> : undefined}>
      {children}
    </HasAccess>
  );
};
