/* eslint-disable max-len */
import { Card, Descriptions, Space, Typography } from 'antd';
import React, { useEffect } from 'react';
import { usePage } from 'src/components/page/Page';
import { useParams } from 'react-router-dom';
import { useGetDiscountByIdQuery } from 'src/graphql/queries/get-discount-by-id';
import { LoadingView } from 'src/components/loading-view/LoadingView';
import { PBACContainer } from 'src/components/RBAC/RBACContainer';
import { PERMISSIONS } from 'src/types/user.type';

export const DiscountDetails = () => {
  const { setSubTitle, setTitle } = usePage();
  const { id } = useParams();
  const { data, loading } = useGetDiscountByIdQuery(id!);

  useEffect(() => {
    setTitle('Discounts');
    setSubTitle('Discount information');
  }, []);

  if (loading) {
    return <LoadingView />;
  }

  const discount = data?.getDiscountById;

  if (!discount) {
    return <Typography.Text>No discount information found.</Typography.Text>;
  }

  return (
    <PBACContainer permissions={[PERMISSIONS.ReadDiscount]}>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Card bordered={false} title={<Typography.Title level={5}>Discount Information</Typography.Title>}>
          <Descriptions layout="horizontal" column={1} labelStyle={{ width: '130px', fontWeight: 'bold' }}>
            <Descriptions.Item label="Type">{discount.type}</Descriptions.Item>
            <Descriptions.Item label="Amount">{discount.amount}</Descriptions.Item>
            <Descriptions.Item label="Start Date">{discount.startDate || 'N/A'}</Descriptions.Item>
            <Descriptions.Item label="End Date">{discount.endDate || 'N/A'}</Descriptions.Item>
            <Descriptions.Item label="Code">{discount.code || 'N/A'}</Descriptions.Item>
            <Descriptions.Item label="Description">{discount.description}</Descriptions.Item>
            <Descriptions.Item label="Is Active">{discount.isActive ? 'Yes' : 'No'}</Descriptions.Item>
            <Descriptions.Item label="Is Online">{discount.isOnline ? 'Yes' : 'No'}</Descriptions.Item>
            <Descriptions.Item label="Is Offline">{discount.isOffline ? 'Yes' : 'No'}</Descriptions.Item>
          </Descriptions>
        </Card>
      </Space>
    </PBACContainer>

  );
};
