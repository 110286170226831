import React, { createContext, useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { INewPassengerParams, PASSENGERT_TYPE } from 'src/types/passanger.type';
import { IRoute } from 'src/types/route.type';
import { ITravelSchedule, ITravelScheduleSearchParams } from 'src/types/travel.type';
import uuid from 'react-uuid';
import { useAuth } from 'src/providers/auth-provider/AuthProvider';
import { IWaybill } from 'src/types/waybill.type';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';
import { PriceBooking } from 'src/graphql/mutations/price-booking';
import {
  CreateReservationResponsePassengers,
  CreateReservationResponseReservation,
  CreateReservationResponseTansaction,
  CreateReservationResponseTickets,
} from 'src/graphql/mutations/create-or-edit-booking';

/**
 * ReservationProvider makes reservation data available.
 *
 */

export interface IReservationProviderProps {
  children: React.ReactNode | React.ReactNode[];
  // Amadosi (2022-08-14) we use this strictly for testing, till we have a better way to mock this provider
  // test?: boolean;
}

type DefaultPassangerOptions = {
  isPrimaryPassenger: boolean;
  routeId: number;
  scheduleId: number;
  organizationId: number;
  tripId: number;
  branchId: number;
};

type ReservationType = 'Passenger' | 'Waybill';

interface ReservationProviderValues {
  newPassengers: INewPassengerParams[];
  passengers: CreateReservationResponsePassengers;
  tickets: CreateReservationResponseTickets;
  schedule?: ITravelSchedule;
  searchParams?: ITravelScheduleSearchParams;
  searchResults?: ITravelSchedule[];
  route?: IRoute;
  transaction?: CreateReservationResponseTansaction;
  waybill?: IWaybill;
  reservation?: CreateReservationResponseReservation;
  reservationType: ReservationType;
  setBookingPrice: (value: PriceBooking) => void;
  bookingPrice?: PriceBooking;
  hasPassengerInformationComplete: boolean;
  setReservationType: (value: ReservationType) => void;
  setPassengers: (passangers: CreateReservationResponsePassengers) => void;
  setSchedule: (schedule: ITravelSchedule) => void;
  setSearchParams: (params: ITravelScheduleSearchParams) => void;
  setSearchResults: (params: ITravelSchedule[]) => void;
  setTickets: (params: CreateReservationResponseTickets) => void;
  setReservation: (params: CreateReservationResponseReservation) => void;
  setTransaction: (params: CreateReservationResponseTansaction) => void;
  setRoute: (route: IRoute) => void;
  getPassengerCount: () => number;
  setWaybill: (waybill: IWaybill) => void;
  setNewPassengers: (newPassengers: INewPassengerParams[]) => void;
}

const ReservationContext = createContext<ReservationProviderValues>({
  setPassengers: () => {},
  setSchedule: () => {},
  setSearchParams: () => {},
  setSearchResults: () => {},
  setRoute: () => {},
  getPassengerCount: () => 0,
  setTickets: () => {},
  setTransaction: () => {},
  passengers: [],
  newPassengers: [],
  tickets: [],
  setWaybill: () => {},
  reservationType: 'Passenger',
  setBookingPrice: () => {},
  setReservation: () => {},
  hasPassengerInformationComplete: false,
  setReservationType: () => {},
  setNewPassengers: () => {},
});

export const useReservation = () => {
  if (!ReservationContext) {
    throw Error('useReservation can only be used within an ReservationProvider');
  }
  return useContext(ReservationContext);
};

const getDefaultPassenger = (type: PASSENGERT_TYPE, options: DefaultPassangerOptions): INewPassengerParams => {
  return {
    fullName: '',
    type,
    trackingId: uuid(),
    isPrimaryPassenger: options.isPrimaryPassenger,
    bloodGroup: '',
    routeId: options.routeId,
    gender: '',
    branchId: options.branchId,
    tripId: options.tripId,
    travelScheduleId: options.scheduleId,
    organizationId: options.organizationId,
    nextKinFirstName: '',
    nextKinLastName: '',
    nextKinPhone: '',
  };
};

export const ReservationProvider = ({ children }: IReservationProviderProps) => {
  const { user } = useAuth();
  const { activeBranch } = useBranch();

  const [passengers, setPassengers] = useState<CreateReservationResponsePassengers>([]);
  const [reservation, setReservation] = useState<CreateReservationResponseReservation>();
  const [newPassengers, setNewPassengers] = useState<INewPassengerParams[]>([]);
  const [transaction, setTransaction] = useState<CreateReservationResponseTansaction>();
  const [bookingPrice, setBookingPrice] = useState<PriceBooking>();
  const [tickets, setTickets] = useState<CreateReservationResponseTickets>([]);
  const [schedule, setSchedule] = useState<ITravelSchedule>();
  const [searchParams, setSearchParams] = useState<ITravelScheduleSearchParams>();
  const [searchResults, setSearchResults] = useState<ITravelSchedule[]>();
  const [route, setRoute] = useState<IRoute>();
  const [waybill, setWaybill] = useState<IWaybill>();
  const [reservationType, setReservationType] = useState<ReservationType>('Passenger');

  useEffect(() => {
    const generateDefaultPassangers = () => {
      const options: DefaultPassangerOptions = {
        routeId: schedule?.routeId ?? 0,
        organizationId: user?.organizationId,
        scheduleId: schedule?.id ?? 0,
        isPrimaryPassenger: false,
        branchId: activeBranch?.id!,
        tripId: schedule?.tripId ?? 0,
      };

      const adultsList = searchParams?.adult ? [...new Array(searchParams?.adult)] : [];

      const adultPassengers = adultsList.map((_, index) =>
        getDefaultPassenger(PASSENGERT_TYPE.ADULT, { ...options, isPrimaryPassenger: index === 0 }),
      );

      let childPassengers: INewPassengerParams[] = [];
      if (searchParams?.child) {
        childPassengers = [...new Array(searchParams?.child)].map((_, index) => {
          return getDefaultPassenger(PASSENGERT_TYPE.CHILD, {
            ...options,
            isPrimaryPassenger: index === 0 && adultsList.length < 1,
          });
        });
      }

      const infantPassengers = searchParams?.infant
        ? [...new Array(searchParams?.infant)].map(() => getDefaultPassenger(PASSENGERT_TYPE.INFANT, options))
        : [];

      setNewPassengers([adultPassengers, childPassengers, infantPassengers].flat());
    };
    generateDefaultPassangers();
  }, [searchParams, schedule, activeBranch]);

  const getPassengerCount = useCallback(() => {
    if (!searchParams) {
      return 0;
    }
    return searchParams.adult + searchParams.child + searchParams.infant;
  }, [searchParams]);

  const hasPassengerInformationComplete = useMemo(() => {
    // check if all the passengers have all their data provided
    return !newPassengers.some((passenger) => passenger.fullName === '');
  }, [newPassengers]);

  const values = useMemo(
    () => ({
      passengers,
      newPassengers,
      reservation,
      schedule,
      setPassengers,
      setNewPassengers,
      setBookingPrice,
      bookingPrice,
      setSchedule,
      searchParams,
      setSearchParams,
      searchResults,
      setSearchResults,
      route,
      setRoute,
      getPassengerCount,
      transaction,
      tickets,
      setTickets,
      setTransaction,
      waybill,
      setWaybill,
      reservationType,
      setReservationType,
      setReservation,
      hasPassengerInformationComplete,
    }),
    [
      newPassengers,
      setNewPassengers,
      setBookingPrice,
      bookingPrice,
      passengers,
      schedule,
      reservation,
      setPassengers,
      setReservation,
      setSchedule,
      searchParams,
      setSearchParams,
      searchResults,
      setSearchResults,
      setRoute,
      route,
      getPassengerCount,
      tickets,
      transaction,
      setTickets,
      setTransaction,
      waybill,
      setWaybill,
      reservationType,
      setReservationType,
      hasPassengerInformationComplete,
    ],
  );

  return <ReservationContext.Provider value={values}>{children}</ReservationContext.Provider>;
};
