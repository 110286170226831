import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ClosingPaper } from 'src/components/closing-document/ClosingDocument';
import { Printable } from 'src/components/print/Print';
import { useGetExpenses } from 'src/hooks/useGetExpenses';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';
import { TripService } from 'src/services/trip.service';
import { IPassenger } from 'src/types/passanger.type';
import { ITrip, ITripHandOver } from 'src/types/trip.type';
import { IWaybill } from 'src/types/waybill.type';
import { PBACContainer } from 'src/components/RBAC/RBACContainer';
import { PERMISSIONS } from 'src/types/user.type';

export const PrintClosingPaper: React.FC = () => {
  const { activeBranch } = useBranch();
  const [tripdata, setTripdata] = useState<ITrip>();
  const [passengers, setPassengers] = useState<IPassenger[]>([]);
  const [waybills, setWaybills] = useState<IWaybill[]>([]);
  const [handovers, setHandovers] = useState<ITripHandOver[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const params = useParams();
  const id = parseInt(params.id!, 10);

  const [{ data: expenses, loading: loadingExpenses }] = useGetExpenses({
    params: { branchId: activeBranch?.id!, tripId: id },
  });

  useEffect(() => {
    const getClosingPaperInformation = async () => {
      setLoading(true);
      const normal = await TripService.findTrip(id);
      const tripPassengers = await TripService.getPassengers(normal?.id);
      const tripWaybills = await TripService.getTripWaybills(normal?.id);
      const handoverData = await TripService.getTripHandOvers(normal?.id);
      setHandovers(handoverData);
      setTripdata(normal);
      setPassengers(tripPassengers);
      setWaybills(tripWaybills);
      setLoading(false);
    };

    getClosingPaperInformation();
  }, [params]);

  return (
    <PBACContainer permissions={[PERMISSIONS.ReadTrip, PERMISSIONS.WriteTrip]}>
      <Printable loading={loading || loadingExpenses}>
        {tripdata && (
          <ClosingPaper
            passengers={passengers}
            tripdata={tripdata}
            expenses={expenses?.items ?? []}
            waybills={waybills}
            handovers={handovers}
          />
        )}
      </Printable>
    </PBACContainer>
  );
};
