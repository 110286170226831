/* eslint-disable max-len */
import { useState } from 'react';
import { Button, Col, Row, Space, Typography } from 'antd';
import Table from 'antd/lib/table';

import { ITrip } from 'src/types/trip.type';
import { IIncome, IIncomeSearchQuery } from 'src/types/income.type';
import { IncomeModal } from 'src/screens/income/components/IncomeModal';
import { tripIncomeColumn } from 'src/screens/trips/TripUtils';
import { isClosed } from 'src/screens/trips/utils/trip-utils';
import { DeleteIncomeAction } from 'src/screens/income/components/DeleteIncomeAction';
import { MoneyText } from 'src/components/topography/MoneyText';
import { useAuth } from 'src/providers/auth-provider/AuthProvider';
import { PERMISSIONS } from 'src/types/user.type';

interface TripIncomeProp {
  trip: ITrip;
  printMode?: boolean;
  loading?: boolean;
  incomes: IIncome[];
  queryFilter: IIncomeSearchQuery;
  fetchIncome: (params?: IIncomeSearchQuery | undefined) => Promise<IIncome[] | undefined>;
  onIncomeActionCompleted: () => Promise<void>;
}
export const TripIncome = ({
  trip,
  printMode,
  incomes,
  fetchIncome,
  queryFilter,
  loading,
  onIncomeActionCompleted,
}: TripIncomeProp) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [initialIncome, setInitialIncome] = useState<IIncome>();

  const onClose = () => {
    setInitialIncome(undefined);
    setShowModal(false);
  };

  const onIncomeEdited = (income: IIncome) => {
    setInitialIncome(income);
    setShowModal(true);
  };

  const onIncomeActionComplete = async () => {
    await fetchIncome(queryFilter);
    await onIncomeActionCompleted();
    setShowModal(false);
  };

  const onDelete = async () => {
    await fetchIncome(queryFilter);
    await onIncomeActionCompleted();
  };

  const { user } = useAuth();

  const incomeSummary = () => {
    const totalExpenses = incomes.reduce((sum: number, income: IIncome) => sum + income.amount, 0);
    return (
      <Table.Summary fixed>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0}>
            <Typography.Text strong>Total</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1}>
            <Typography.Text strong>
              <MoneyText value={totalExpenses} />
            </Typography.Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  const isClosedTrip = isClosed(trip);

  let dynamicColumn = tripIncomeColumn;
  if (!printMode && !isClosedTrip) {
    dynamicColumn = [
      ...tripIncomeColumn,
      {
        title: 'Action',
        render: (income: IIncome) => (
          <Space>
            <Button type="text" onClick={() => onIncomeEdited(income)}>
              Edit
            </Button>
            <DeleteIncomeAction id={income.id} onDelete={onDelete} />
          </Space>
        ),
      },
    ];
  }

  if (printMode && incomes.length === 0) {
    return null;
  }

  return (
    <>
      <Row className="mb-30 mt-30">
        <Col sm={{ span: 4 }}>
          <Typography.Title level={3}>Income</Typography.Title>
        </Col>

        <Col lg={{ span: 3, offset: 17 }} md={{ offset: 20 }} sm={{ span: 4 }} xs={{ offset: 14 }}>
          {!isClosed(trip) && !printMode && user?.permissions?.includes(PERMISSIONS.WriteTrip) && ( // Should be WriteIncome -- Awaiting update
            <Button type="primary" block onClick={() => setShowModal(true)}>
              Add Income
            </Button>
          )}
        </Col>
      </Row>

      <Table
        rowKey={(row: IIncome) => row.id}
        columns={dynamicColumn}
        dataSource={incomes}
        bordered
        size="small"
        loading={loading}
        pagination={false}
        summary={incomeSummary}
      />
      {showModal && (
        <IncomeModal
          show={showModal}
          trip={trip}
          initialIncome={initialIncome}
          onIncomeActionCompleted={onIncomeActionComplete}
          onClose={onClose}
        />
      )}
    </>
  );
};
