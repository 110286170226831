/* eslint-disable max-len */
import { useEffect, useState } from 'react';
import { Button, Col, DatePicker, Form, Input, InputNumber, message, Row, Select } from 'antd';
import { useNavigate } from 'react-router-dom';

import { useReservation } from 'src/providers/reservation-provider/ReservationContext';
import { ReservationService } from 'src/services/reservation.service';
import { RoutesService } from 'src/services/routes.service';
import { IDestination } from 'src/types/branch.type';
import { ICreateWayBillParams } from 'src/types/waybill.type';
import 'src/screens/waybill/waybill-form/styles/waybill.style.less';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';
import { onDestinationFilter } from 'src/utilities/helpers.utils';
import { useAuth } from 'src/providers/auth-provider/AuthProvider';
import { PERMISSIONS } from 'src/types/user.type';

const { Option } = Select;
const { TextArea } = Input;

export const WAYBILL_FORM_TEST_ID = 'WAYBILL_FORM_TEST_ID';

export const WaybillForm = () => {
  const [form] = Form.useForm();
  const { activeBranch } = useBranch();
  const { user } = useAuth();

  const [loading, setLoading] = useState<boolean>(false);
  const [destination, setDestination] = useState<IDestination[]>([]);
  const [selectedOthers, setSelectedOthers] = useState<boolean>(false);

  const { setWaybill, setTransaction } = useReservation();
  const navigate = useNavigate();

  const selectOthersType = (value: string) => {
    if (value === 'others') {
      setSelectedOthers(true);
    }
  };

  const DestinationOptions = destination.map((item: IDestination) => (
    <Option value={item.id} key={item.id}>
      {item.name} ({item.code})
    </Option>
  ));

  useEffect(() => {
    const getData = async () => {
      try {
        const result = await RoutesService.getDestinations();
        setDestination(result);
      } catch (error) {
        message.error('There was an error fetching data', 2.5);
      }
    };

    getData();
  }, [setDestination]);

  const handleSave = async (payload: ICreateWayBillParams) => {
    setLoading(true);
    try {
      const result = await ReservationService.createWaybill({
        ...payload,
      });
      setWaybill(result.waybill);
      setTransaction({
        id: String(result.transaction.id),
        amount: result.transaction.amount,
        totalAmount: result.transaction.amount + result.transaction.serviceCharge,
        serviceCharge: result.transaction.serviceCharge,
      });
      form.resetFields();
      navigate('/ticketing/payment-confirmation');
    } catch (error) {
      message.error('Something went wrong, please try again', 2.5);
    }
    setLoading(false);
  };

  const handleSubmit = async () => {
    try {
      const values = await form.validateFields();
      const payload: ICreateWayBillParams = {
        ...values,
        date: values.date.format('YYYY-MM-DD'),
        branchId: activeBranch?.id,
        noTransform: true,
      };
      handleSave(payload);
    } catch (e) {
      message.error('Invalid data entered', 2.5);
    }
  };

  const onDestinationSearch = (input: string, option?: { value: number }) => {
    return onDestinationFilter(destination, input, option);
  };

  const hasPermission = user?.permissions?.includes(PERMISSIONS.WriteWaybill);

  return (
    <div className="form-container-card">
      <Form
        style={{ width: '100%' }}
        size="large"
        layout="vertical"
        autoComplete="on"
        form={form}
        data-testid={WAYBILL_FORM_TEST_ID}
        className="form-container"
      >
        <Row>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              label="Waybill type"
              name="type"
              rules={[{ required: true, message: 'Please select a waybill type' }]}
            >
              <Select showSearch placeholder="Select type" onSelect={selectOthersType} className="form-item-input" disabled={!hasPermission}>
                <Option value="document parcel">Document parcel</Option>
                <Option value="clothing parcel">Clothing parcel</Option>
                <Option value="furniture parcel">Furniture parcel</Option>
                <Option value="food parcel">Food parcel</Option>
                <Option value="others">others</Option>
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              label="Carrier / Sender's Full Name"
              name="sendersName"
              rules={[{ required: true, message: "Please enter carrier / sender's Full Name" }]}
            >
              <Input placeholder="example" disabled={!hasPermission} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              label="Carrier / Sender's Phone Number"
              name="sendersPhone"
              rules={[{ required: true, message: "Please enter carrier / sender's Phone Number" }]}
            >
              <Input placeholder="+234..." disabled={!hasPermission} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              label="Carrier / Sender's Email"
              name="sendersEmail"
              rules={[{ required: true, message: "Please enter carrier / sender's Email" }]}
            >
              <Input placeholder="example@gmail.com" disabled={!hasPermission} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              label="Recipient's Full Name"
              name="recipientName"
              rules={[{ required: true, message: 'Please enter Recipient Full Name' }]}
            >
              <Input placeholder="example" disabled={!hasPermission} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              label="Recipient's Phone Number"
              name="recipientPhone"
              rules={[{ required: true, message: 'Please enter Recipient Phone Number' }]}
            >
              <Input placeholder="+234..." disabled={!hasPermission} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item label="Select date" name="date" rules={[{ required: true, message: 'Please pick a date' }]}>
              <DatePicker className="date-picker" format="YYYY-MM-DD" disabled={!hasPermission} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              label="Destination"
              name="destinationId"
              rules={[{ required: true, message: 'Please pick a destination' }]}
            >
              <Select showSearch placeholder="Arriving Terminal" loading={loading} filterOption={onDestinationSearch} disabled={!hasPermission}>
                {DestinationOptions}
              </Select>
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              label="Quantity"
              name="quantity"
              rules={[{ required: true, message: 'Please enter some quantity' }]}
            >
              <InputNumber placeholder="0" type="number" className="w-100" disabled={!hasPermission} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              label="Amount"
              name="amount"
              rules={[{ required: true, message: 'Please enter waybill amount' }]}
            >
              <InputNumber placeholder="0.00" type="number" addonBefore="₦" className="w-100" disabled={!hasPermission} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item
              label="Item Value"
              name="value"
              rules={[{ required: true, message: 'Please specify item value' }]}
            >
              <InputNumber addonBefore="₦" className="w-100" disabled={!hasPermission} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item label="Description" name="description" required={selectedOthers}>
              <TextArea placeholder="Enter description here" allowClear disabled={!hasPermission} />
            </Form.Item>
          </Col>
          <Col xs={{ span: 24 }} md={{ span: 24 }} lg={{ span: 24 }}>
            <Form.Item>
              <Button block htmlType="submit" size="large" onClick={handleSubmit} loading={loading} disabled={!hasPermission}>
                Proceed to Payment
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    </div>
  );
};
