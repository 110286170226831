import { useState, useEffect } from 'react';
import { Button, Table, Card } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';

import { usePage } from 'src/components/page/Page';
import 'src/screens/routes-management/styles/routesManagement.styles.less';
import { useGetDestinations } from 'src/hooks/useGetDestinations';
import { IDestination } from 'src/types/branch.type';
import { destinationColumns } from 'src/screens/destinations/DestinationUtils';
import { DestinationModal } from 'src/screens/destinations/components/DestinationModal';
import { PBACContainer } from 'src/components/RBAC/RBACContainer';
import { useAuth } from 'src/providers/auth-provider/AuthProvider';
import { PERMISSIONS } from 'src/types/user.type';

export const Destinations = () => {
  const { setSubTitle, setTitle } = usePage();
  const { user } = useAuth();
  const [{ data, loading }, fetchDestinations] = useGetDestinations();

  const [selectedDestination, setSelectedDestination] = useState<IDestination>();
  const [showDestinationModal, setShowDestinationModal] = useState<boolean>(false);

  useEffect(() => {
    setTitle('Destinations');
    setSubTitle('Manage Destinations');
  }, [setTitle, setSubTitle]);

  const closeDestinationModal = () => {
    setSelectedDestination(undefined);
    setShowDestinationModal(false);
  };

  const showEditDestinationModal = (id: number) => {
    const route = data?.find((r) => r.id === id);
    setSelectedDestination(route);
    setShowDestinationModal(true);
  };

  const onDestinationActionComplete = async () => {
    setShowDestinationModal(false);
    await fetchDestinations();
  };

  const columns = [...destinationColumns];
  columns.push({
    title: 'Action',
    dataIndex: 'id',
    key: 'id',
    render: (id: number) => {
      return (
        <Button
          type="ghost"
          onClick={() => showEditDestinationModal(id)}
          className="more-btn"
          href="#"
          disabled={!user.permissions?.includes(PERMISSIONS.WriteDestination)}
          icon={<EditOutlined />}
        />
      );
    },
    fixed: 'right',
    width: 100,
  });

  return (
    <div className="routes-page-container">
      <PBACContainer permissions={[PERMISSIONS.ReadDestination, PERMISSIONS.WriteDestination]}>
        <Card
          className="card"
          bordered={false}
          extra={[
            <PBACContainer permissions={[PERMISSIONS.WriteDestination]}>
              <Button
                key="add-route-key"
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => setShowDestinationModal(true)}
              >
                Add New
              </Button>
            </PBACContainer>,
          ]}
        >
          <Table
            loading={loading}
            rowKey="id"
            columns={columns}
            dataSource={data}
            size="small"
            bordered
            pagination={{ pageSize: 100, total: data?.length }}
          />
        </Card>

        {showDestinationModal && (
          <DestinationModal
            show={showDestinationModal}
            onClose={closeDestinationModal}
            onDestinationActionCompleted={onDestinationActionComplete}
            defaultDestination={selectedDestination}
          />
        )}
      </PBACContainer>
    </div>
  );
};
