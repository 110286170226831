/* eslint-disable max-len */
import { useState, useEffect } from 'react';

import { Button, Table, Card, Space, Popconfirm } from 'antd';
import { CloseCircleOutlined, EyeOutlined, PlusOutlined, PrinterOutlined } from '@ant-design/icons';

import 'src/screens/passengers/styles/passengers.styles.less';
import { usePage } from 'src/components/page/Page';
import { TICKET_STATUS } from 'src/types/ticket.type';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';
import { IFindPassengersQueryParams, IPassenger } from 'src/types/passanger.type';
import { Link, useNavigate } from 'react-router-dom';
import { SearchBar } from 'src/components/search-bar/SearchBar';
import { passengerColumns } from 'src/screens/passengers/utils/Passenger.utils';
import { usePostCancelTicket } from 'src/hooks/usePostCancelTicket';
import { useGetPassengers } from 'src/hooks/useGetPassengers';
import { useAuth } from 'src/providers/auth-provider/AuthProvider';
import { PBACContainer } from 'src/components/RBAC/RBACContainer';
import { PERMISSIONS } from 'src/types/user.type';

export const PASSSENGERS_PAGE_TEST_ID = 'SALE_HISTORY_PAGE_TEST_ID';

export const Passengers = () => {
  const { setSubTitle, setTitle } = usePage();
  const { activeBranch } = useBranch();
  const navigate = useNavigate();
  const { hasPermission } = useAuth();

  const [ticketFilter, setTicketFilter] = useState<IFindPassengersQueryParams>({
    branchId: activeBranch?.id!,
    ticketStatus: [TICKET_STATUS.ISSUED, TICKET_STATUS.CANCELLED],
    page: 1,
    offset: 100,
  });
  const [cancelTicket, { loading: cancelTicketLoading }] = usePostCancelTicket();
  const [{ data, loading }, refetchPassengers] = useGetPassengers(ticketFilter);

  useEffect(() => {
    setTitle('Passengers');
    setSubTitle('Passenger information');
  }, []);

  const onSearch = async (value: string) => {
    const status = [TICKET_STATUS.ISSUED, TICKET_STATUS.CANCELLED];
    if (value) {
      status.push(TICKET_STATUS.ON_HOLD);
    }

    setTicketFilter({
      ...ticketFilter,
      search: value,
      ticketStatus: status,
    });
  };

  const onCancelTicket = async (passengerId: number) => {
    const passanger = data?.items.find((p) => p.id === passengerId);
    if (passanger) {
      await cancelTicket(passanger.ticket?.id!);
      await refetchPassengers(ticketFilter);
    }
  };

  const onPageChange = (page: number) => {
    setTicketFilter({ ...ticketFilter, page });
  };

  const columns = [...passengerColumns];
  columns.push({
    title: 'Action',
    fixed: 'right',
    width: 100,
    render: (passenger: IPassenger) => (
      <Space split="|">
        {hasPermission(PERMISSIONS.ReadPassenger) && (
          <Link to={`/passengers/${passenger.ticket?.id}`}>
            <EyeOutlined />
          </Link>
        )}

        <Link to={`/ticket/${passenger.ticket?.id}/print`} target="_">
          <PrinterOutlined />
        </Link>

        {passenger.ticket?.status !== TICKET_STATUS.CANCELLED && hasPermission(PERMISSIONS.CancelTicket) && (
          <Popconfirm
            title="Are you sure to cancel this ticket?"
            onConfirm={() => onCancelTicket(passenger.id)}
            okText="Yes"
            cancelText="No"
          >
            <CloseCircleOutlined style={{ color: '#c0392b' }} />
          </Popconfirm>
        )}
      </Space>
    ),
  });

  return (
    <div className="history-container" data-testid={PASSSENGERS_PAGE_TEST_ID}>
      <PBACContainer
        permissions={[
          PERMISSIONS.ReadPassenger,
          PERMISSIONS.WritePassenger,
          PERMISSIONS.DeleteTicket,
          PERMISSIONS.DeletePassenger,
        ]}
      >
        <Card
          className="card"
          bordered={false}
          extra={[
            <div key="search-container" className="search-container">
              <SearchBar
                width={500}
                className="passenger-search"
                placeHolder="Name, Ref, or Phone No"
                onSearch={onSearch}
                loading={loading}
              />
              <PBACContainer permissions={[PERMISSIONS.WritePassenger]}>
                <Button onClick={() => navigate('/ticketing')} key="add-new-bus" type="primary" icon={<PlusOutlined />}>
                  Add New
                </Button>
              </PBACContainer>
            </div>,
          ]}
        >
          <Table
            rowKey="id"
            columns={columns}
            dataSource={data?.items}
            loading={loading || cancelTicketLoading}
            pagination={{
              pageSize: data?.meta.offset,
              total: data?.meta.totalItems,
              onChange: onPageChange,
              current: data?.meta.page,
            }}
            scroll={{ x: 1700 }}
            sortDirections={['ascend']}
          />
        </Card>
      </PBACContainer>
    </div>
  );
};
