import { IUser, USER_ROLE, PERMISSIONS } from 'src/types/user.type';
import { api } from 'src/services/api';
import camelcaseKeys from 'camelcase-keys';
import { resources } from 'src/services/api/resources';
import { useUpdate } from 'src/hooks/shared/useUpdate';

export interface UseCreateUserParams {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  organizationId: number;
  password: string;
  driversLicenseNumber?: string;
  roles?: USER_ROLE[];
  permissions: PERMISSIONS[];
  branchIds?: number[];
}

export const useCreateUser = () =>
  useUpdate<IUser, UseCreateUserParams>(async (options) => {
    const payload = { ...options?.params, noTransform: true };
    const { data } = await api.post<IUser>(resources.userUrl, payload);
    return camelcaseKeys(data, { deep: true });
  });
