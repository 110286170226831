import { PrinterOutlined } from '@ant-design/icons';
import { Button, List, Tag } from 'antd';
import { FC } from 'react';
import {
  CreateReservationResponseTickets,
  CreateReservationResponsePassengers,
} from 'src/graphql/mutations/create-or-edit-booking';

interface TicketListProps {
  tickets: CreateReservationResponseTickets;
  passengers: CreateReservationResponsePassengers;
}

export const TicketList: FC<TicketListProps> = ({ tickets, passengers }) => {
  return (
    <List
      itemLayout="horizontal"
      dataSource={tickets}
      renderItem={(ticket: CreateReservationResponseTickets[number]) => {
        const passenger = passengers.find((pass) => pass.id === String(ticket.passengerId));
        return (
          <List.Item key={ticket.id}>
            <List.Item.Meta
              title={
                <span>
                  {passenger?.fullName} <Tag>{passenger?.type}</Tag>
                </span>
              }
              description={passenger?.pnr}
            />
            <Button
              href={`/ticket/${ticket.id}/print`}
              target="blank"
              type="primary"
              shape="round"
              icon={<PrinterOutlined />}
              size="small"
            >
              Print
            </Button>
          </List.Item>
        );
      }}
    />
  );
};
