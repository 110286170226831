/* eslint-disable max-len */
import { useState, useEffect } from 'react';
import { Table, Card, Row, Col, Badge } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { usePage } from 'src/components/page/Page';
import 'src/screens/transactions/styles/transactionPage.styles.less';
import { TransactionsFilter } from 'src/screens/transactions/components/TransactionFilter';
import { MoneyText } from 'src/components/topography/MoneyText';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';
import { ITransaction, PAYMENT_METHODS, PAYMENT_METHOD_LABELS, TRANSACTION_STATUS } from 'src/types/transaction.type';
import { SummaryCard } from 'src/screens/transactions/components/SummaryCard';
import { Cash } from 'src/icons/Cash.icon';
import { Pos } from 'src/icons/Pos.icon';
import { Transfer } from 'src/icons/Transfer.icon';

import { ITransactionQueryParams, useGetTransactions } from 'src/hooks/useGetTransactions';
import { IUser, PERMISSIONS } from 'src/types/user.type';
import { UseGetTransactionAnalysisParams, useGetTransactionAnalysis } from 'src/hooks/useGetTransactionAnalysis';
import { DEFAULT_DATE_FORMAT } from 'src/utilities/date.utils';
import { FilterOptions } from 'src/screens/dashboard/components/FilterOptions';
import { EyeOutlined } from '@ant-design/icons';
import { PBACContainer } from 'src/components/RBAC/RBACContainer';
import { useAuth } from 'src/providers/auth-provider/AuthProvider';

export const TRANSACTION_PAGE_TEST_ID = 'TRANSACTION_PAGE_TEST_ID';

export const getTransactionStatusBadge = (status: string) => {
  if (status === TRANSACTION_STATUS.CANCELLED) return <Badge status="error" text="cancelled" />;
  if (status === TRANSACTION_STATUS.PENDING) return <Badge status="processing" text="issued" />;
  if (status === TRANSACTION_STATUS.PROCESSED) return <Badge status="success" text="processed" />;
  if (status === TRANSACTION_STATUS.REVERSED) return <Badge status="error" text="reversed" />;

  return null;
};

export const TransactionsScreen = () => {
  const { activeBranch } = useBranch();
  const { hasPermission } = useAuth();

  const [queryFilter, setQueryFilter] = useState<ITransactionQueryParams>({
    branchId: activeBranch?.id!,
    status: TRANSACTION_STATUS.PROCESSED,
    startDate: moment().format(DEFAULT_DATE_FORMAT),
    page: 1,
    offset: 10,
  });
  const [analysisParam, setAnalysisParam] = useState<UseGetTransactionAnalysisParams>({
    terminalId: activeBranch?.id!,
    startDate: moment().format(DEFAULT_DATE_FORMAT),
  });

  const [{ data: analysis, loading: loadingAnalysis }] = useGetTransactionAnalysis({ params: analysisParam });
  const [{ data, loading }] = useGetTransactions({ params: queryFilter });
  const { setSubTitle, setTitle } = usePage();

  useEffect(() => {
    setTitle('Transactions');
    setSubTitle('');
  }, []);

  const onFilterChange = async (filter: ITransactionQueryParams) => {
    setQueryFilter({ ...queryFilter, ...filter });
  };

  const onPageChange = (page: number) => {
    setQueryFilter({ ...queryFilter, page });
  };

  const onAnalysisFilterChanged = (start: string | null, end: string | null) => {
    setAnalysisParam({ ...analysisParam, startDate: start ?? '', endDate: end ?? '' });
    setQueryFilter({ ...queryFilter, startDate: start ?? '', endDate: end ?? '' });
  };

  const totalTransactions = (analysis?.cashTotal ?? 0) + (analysis?.transferTotal ?? 0) + (analysis?.posTotal ?? 0);
  const totalSettlement =
    (analysis?.cashSettlement ?? 0) + (analysis?.transferSettlement ?? 0) + (analysis?.posSettlement ?? 0);

  const columns: ColumnsType<ITransaction> = [
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
      render: (charge, transaction) => <MoneyText value={charge + transaction.serviceCharge} />,
    },
    {
      title: 'Date',
      dataIndex: 'createdAt',
      key: 'created_at',
      render: (date) => <span>{moment(date).format('ddd, Do MMM YYYY')}</span>,
    },
    {
      title: 'Transaction type',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Payment method',
      dataIndex: 'paymentMethod',
      key: 'paymentMethod',
      render: (paymentMethod: PAYMENT_METHODS) => PAYMENT_METHOD_LABELS[paymentMethod],
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status: string) => getTransactionStatusBadge(status),
    },
    {
      title: 'Processed by',
      dataIndex: 'ticketer',
      key: 'ticketer',
      render: (ticketer: IUser) => <span>{`${ticketer?.firstName} ${ticketer?.lastName}`}</span>,
    },
  ];

  if (hasPermission(PERMISSIONS.ReadTransactions)) {
    columns.push({
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id) => (
        <Link to={`/transactions/${id}`}>
          <EyeOutlined />
        </Link>
      ),
      align: 'center',
      width: 100,
    });
  }

  return (
    <div data-testid={TRANSACTION_PAGE_TEST_ID}>
      <PBACContainer permissions={[PERMISSIONS.ReadTransactions, PERMISSIONS.WriteTransactions]}>
        <Card>
          <div className="summary-filter" style={{ marginBottom: 10 }}>
            <FilterOptions onRangeSelected={onAnalysisFilterChanged} />
          </div>
          <Row>
            <Col sm={24} md={6}>
              <SummaryCard
                title="Total Cash"
                figure={analysis?.cashTotal ?? 0}
                descriptionTitle="Settlement"
                description={<Card.Meta description={<MoneyText value={analysis?.cashSettlement ?? 0} showCurrency />} />}
                icon={<Cash />}
                isMoney
                loading={loadingAnalysis}
              />
            </Col>
            <Col sm={24} md={6}>
              <SummaryCard
                title="Total POS Terminal"
                figure={analysis?.posTotal ?? 0}
                descriptionTitle="Settlement"
                description={<Card.Meta description={<MoneyText value={analysis?.posSettlement ?? 0} showCurrency />} />}
                icon={<Pos />}
                isMoney
                loading={loadingAnalysis}
              />
            </Col>
            <Col sm={24} md={6}>
              <SummaryCard
                title="Total Bank Transfer"
                descriptionTitle="Settlement"
                figure={analysis?.transferTotal ?? 0}
                description={
                  <Card.Meta description={<MoneyText value={analysis?.transferSettlement ?? 0} showCurrency />} />
                }
                icon={<Transfer />}
                isMoney
                loading={loadingAnalysis}
              />
            </Col>
            <Col sm={24} md={6}>
              <SummaryCard
                title="Total Transactions"
                figure={totalTransactions}
                loading={loadingAnalysis}
                descriptionTitle="Settlement"
                description={<Card.Meta description={<MoneyText value={totalSettlement} showCurrency />} />}
                isMoney
              />
            </Col>
          </Row>
        </Card>
      </PBACContainer>

      <Card bordered={false}>
        <TransactionsFilter onChange={onFilterChange} />
      </Card>
      <Card bordered={false}>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={data?.items}
          loading={loading}
          pagination={{
            pageSize: data?.meta.offset,
            total: data?.meta.totalItems,
            onChange: onPageChange,
            current: data?.meta.page,
          }}
          sortDirections={['ascend']}
          scroll={{ x: 1000 }}
        />
      </Card>
    </div>
  );
};
