/* eslint-disable max-len */
import { Button, Col, Descriptions, Form, Input, Row, Space, Table, Tooltip, Typography } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { LoadingView } from 'src/components/loading-view/LoadingView';
import { MoneyText } from 'src/components/topography/MoneyText';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';
import { ExpenseModal } from 'src/screens/expenses/components/ExpenseModal';
import { IExpense, IExpenseSearchQuery } from 'src/types/expense.type';
import { IPassenger } from 'src/types/passanger.type';
import { ITrip, ITripHandOver } from 'src/types/trip.type';
import { IWaybill } from 'src/types/waybill.type';
import { isClosed } from 'src/screens/trips/utils/trip-utils';
import { PrinterFilled } from '@ant-design/icons';
import {
  AllDestination,
  AllWaybills,
  destinationColumn,
  groupHandoversByDestination,
  groupPassengers,
  groupPassengersByDestination,
  groupWaybills,
  groupWaybillsByDestination,
  handoverColumn,
  tripExpenceColumn,
  tripIncomeColumn,
  waybillColumn,
} from 'src/screens/trips/TripUtils';
import { TICKET_STATUS } from 'src/types/ticket.type';
import { useGetExpenses } from 'src/hooks/useGetExpenses';
import { useGetTripPassengers } from 'src/hooks/useGetTripPassengers';
import { useGetTripWaybills } from 'src/hooks/useGetTripWaybills';
import { useGetTripHandovers } from 'src/hooks/useGetTripHandovers';
import { DeleteExpenseAction } from 'src/screens/expenses/components/DeleteExpenseAction';
import { TripIncome } from 'src/screens/trips/components/TripIncome';
import { useGetIncome } from 'src/hooks/useGetIncome';
import { IIncome, IIncomeSearchQuery } from 'src/types/income.type';
import { CLOSE_TRIP_MESSAGE, PRINT_CLOSING_BOOK_TIP } from 'src/screens/trips/constants';
import logo from 'src/icons/Logo.png';
import 'src/screens/trips/components/styles/tripClosingBook.styles.less';
import { useGetTripReport } from 'src/graphql/queries/get-trip-report';
import { useAuth } from 'src/providers/auth-provider/AuthProvider';
import { PERMISSIONS } from 'src/types/user.type';

export const TripClosingBook = ({ trip, printMode = false }: { trip: ITrip; printMode?: boolean }) => {
  const { activeBranch } = useBranch();

  const [initialExpense, setInitialExpense] = useState<IExpense>();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [queryFilter] = useState<IIncomeSearchQuery>({
    tripId: trip.id,
    branchId: activeBranch?.id!,
  });

  const [filterQuery, setFilterQuery] = useState<IExpenseSearchQuery>({
    branchId: activeBranch?.id!,
    tripId: trip.id,
    offset: 100,
  });

  const {
    data: tripReportData,
    loading: tripReportLoading,
    refetch: fetchTripReport,
  } = useGetTripReport(String(trip.id));
  const [{ data: expenses, loading: tripExpenseLoading }, fetchExpenses] = useGetExpenses({ params: filterQuery });
  const [{ data: passengers = [], loading: loadingPassengers }] = useGetTripPassengers(trip.id);
  const [{ data: waybills = [], loading: loadingWaybills }] = useGetTripWaybills(trip.id);
  const [{ data: handovers = [], loading: loadingHandovers }] = useGetTripHandovers(trip.id);
  const [{ data: incomes = [], loading: loadingIncome }, fetchIncome] = useGetIncome(queryFilter);

  const allDestinations = groupPassengersByDestination(passengers);
  const allWaybills = groupWaybillsByDestination(waybills);
  const allHandovers = groupHandoversByDestination(handovers);
  const isClosedTrip = isClosed(trip);
  const { user } = useAuth();

  const onExpenseActionCompleted = async () => {
    await fetchExpenses({ params: filterQuery });
    await fetchTripReport({ tripId: String(trip.id) });
    setInitialExpense(undefined);
    setShowModal(false);
  };

  const onIncomeActionCompleted = async () => {
    await fetchTripReport({ tripId: String(trip.id) });
  };

  const expenseSummary = () => {
    const totalExpenses = expenses?.items.reduce((sum: number, expense: IExpense) => sum + expense.amount, 0);

    return (
      <Table.Summary fixed>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0}>
            <Typography.Text strong>Total</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1}>
            <Typography.Text strong>
              <MoneyText value={totalExpenses!} />
            </Typography.Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  const allDestinationSummary = () => {
    const totalDestination = allDestinations?.reduce(
      (sum: number, destinations: AllDestination) => sum + destinations.subTotal,
      0,
    );
    return (
      <Table.Summary fixed>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0}>
            <Typography.Text strong>Total</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={0}>
            <Typography.Text strong> </Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1}>
            <Typography.Text strong>
              <MoneyText value={totalDestination!} />
            </Typography.Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  const allWaybillsDestinationSummary = () => {
    const totalDestination = allWaybills?.reduce(
      (sum: number, destinations: AllWaybills) => sum + destinations.subTotal,
      0,
    );
    return (
      <Table.Summary fixed>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0}>
            <Typography.Text strong>Total</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={0}>
            <Typography.Text strong> </Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1}>
            <Typography.Text strong>
              <MoneyText value={totalDestination!} />
            </Typography.Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  const allHandOverSummary = () => {
    const totalDestination = allHandovers?.reduce(
      (sum: number, destinations: AllDestination) => sum + destinations.subTotal,
      0,
    );
    return (
      <Table.Summary fixed>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0}>
            <Typography.Text strong>Total</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={0}>
            <Typography.Text strong> </Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1}>
            <Typography.Text strong>
              <MoneyText value={totalDestination!} />
            </Typography.Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  const allIncomeSummary = () => {
    const totalDestination = incomes.reduce((sum: number, income: IIncome) => sum + income.amount, 0);

    return (
      <Table.Summary fixed>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0}>
            <Typography.Text strong>Total</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1}>
            <Typography.Text strong>
              <MoneyText value={totalDestination!} />
            </Typography.Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  const onExpenseEdited = (expense: IExpense) => {
    setInitialExpense(expense);
    setShowModal(true);
  };

  const onExpenseDeleted = async () => {
    await fetchExpenses({ params: filterQuery });
    await fetchTripReport({ tripId: String(trip.id) });
  };

  const dynamicColumn = [...tripExpenceColumn];
  if (!isClosedTrip && (user?.permissions?.includes(PERMISSIONS.WriteExpense) || user?.permissions?.includes(PERMISSIONS.DeleteExpense))) {
    dynamicColumn.push({
      title: 'Action',
      render: (expense: IExpense) => (
        <Space>
          {user?.permissions?.includes(PERMISSIONS.WriteExpense) && (
            <Button
              type="text"
              onClick={() => onExpenseEdited(expense)}
            >
              Edit
            </Button>
          )}
          {user?.permissions?.includes(PERMISSIONS.DeleteExpense) && (
            <DeleteExpenseAction
              id={expense.id}
              onDelete={onExpenseDeleted}
            />
          )}
        </Space>
      ),
    });
  }

  const onPageChange = (currenctPage: number, newPageSize: number) => {
    setFilterQuery({ ...filterQuery, page: currenctPage, offset: newPageSize });
  };

  const onCancel = () => {
    setInitialExpense(undefined);
    setShowModal(false);
  };

  const groupedPassengers = groupPassengers(passengers.filter((p) => p.ticket?.status === TICKET_STATUS.ISSUED));
  const groupedWaybills = groupWaybills(waybills);
  const totalHandovers = handovers.reduce((sum: number, handover: ITripHandOver) => sum + handover.amount, 0);

  if (loadingHandovers || !trip || loadingPassengers || loadingWaybills || tripReportLoading) return <LoadingView />;

  const dateTime = `${trip.date} ${trip.time}`;

  return (
    <>
      <div className="logo-container">
        <img src={logo} alt="company logo" />
        <Typography.Title level={5}>{moment(dateTime).format('Do MMMM YYYY').toUpperCase()}</Typography.Title>
      </div>

      <div style={{ marginTop: 20 }}>
        {!printMode ? (
          <Descriptions title={`${trip.branch?.name.toUpperCase()} TO ${trip.destination?.name.toUpperCase()}`}>
            {(() => {
              const keys = Object.keys(groupedPassengers);
              return keys.map((key) => {
                const passengersGroup = groupedPassengers[key];
                const total = passengersGroup.reduce(
                  (sum: number, passenger: IPassenger) =>
                    sum + passenger.ticket!.charge + (passenger.ticket?.serviceCharge ?? 0),
                  0,
                );
                return (
                  <Descriptions.Item label={`${passengersGroup.length}X Passengers to ${key}`}>
                    <MoneyText value={total} />
                  </Descriptions.Item>
                );
              });
            })()}

            {(() => {
              const keys = Object.keys(groupedWaybills);
              return keys.map((key) => {
                const waybillsGroup = groupedWaybills[key];
                const total = waybillsGroup.reduce((sum: number, waybill: IWaybill) => sum + waybill.amount, 0);
                return (
                  <Descriptions.Item label={`${waybillsGroup.length}X Waybills to ${key}`}>
                    <MoneyText value={total} />
                  </Descriptions.Item>
                );
              });
            })()}
          </Descriptions>
        ) : (
          <div style={{ fontWeight: 'bold', marginBottom: '2.5rem', paddingLeft: ' 0.5rem' }}>
            <p>{`Branch: ${trip.branch?.name.toUpperCase()} `}</p>
            <p>{`Destination: ${trip.destination?.name.toUpperCase()} `}</p>
            <p>{`Vehicle No: ${trip.vehicle?.vehicleNumber.toUpperCase() || 'Unassigned'} `}</p>
            <p>{`Driver Name: ${trip.driver?.lastName.toUpperCase() || 'Unassigned'}`}</p>
          </div>
        )}

        {trip.driver && (
          <Descriptions title="Driver" labelStyle={{ fontWeight: 'bold' }}>
            <Descriptions.Item label="Name">{`${trip.driver?.firstName} ${trip.driver?.lastName}`}</Descriptions.Item>
          </Descriptions>
        )}

        {printMode && (
          <Descriptions>
            <Descriptions.Item>
              <Form name="basic">
                <Form.Item label="No of seats">
                  <Input />
                </Form.Item>
              </Form>
            </Descriptions.Item>
          </Descriptions>
        )}
      </div>
      {!printMode && (
        <div style={{ marginTop: 20 }}>
          <Descriptions title="Hand Overs">
            <Descriptions.Item label="TOTAL">
              <MoneyText value={totalHandovers} />
            </Descriptions.Item>
          </Descriptions>
        </div>
      )}

      <div>
        {!printMode && (
          <Row className="mb-30">
            <Col lg={{ span: 3, offset: 21 }} md={{ offset: 20 }} sm={{ span: 4 }} xs={{ offset: 14 }}>
              {!isClosedTrip && user?.permissions?.includes(PERMISSIONS.WriteExpense) && (
                <Button type="primary" block onClick={() => setShowModal(true)}>
                  Add Expense
                </Button>
              )}
            </Col>
          </Row>
        )}

        {!printMode ? (
          <Table
            rowKey="id"
            columns={dynamicColumn}
            dataSource={expenses?.items}
            bordered
            size="small"
            loading={tripExpenseLoading}
            pagination={{
              pageSize: expenses?.meta.offset,
              current: expenses?.meta.page,
              onChange: onPageChange,
              showSizeChanger: true,
              total: expenses?.meta.totalItems,
            }}
            summary={expenseSummary}
          />
        ) : (
          <>
            <Table
              rowKey="destinationId"
              columns={destinationColumn}
              dataSource={allDestinations}
              bordered
              size="small"
              loading={tripExpenseLoading}
              pagination={false}
              summary={allDestinationSummary}
              title={() => 'Passengers Summary:'}
            />
            <br />
            <Table
              rowKey="destinationId"
              columns={waybillColumn}
              dataSource={allWaybills}
              size="small"
              loading={tripExpenseLoading}
              pagination={false}
              summary={allWaybillsDestinationSummary}
              title={() => 'Waybill / Loads Summaries'}
            />
            <br />
            <Table
              rowKey="destinationId"
              columns={handoverColumn}
              dataSource={allHandovers}
              size="small"
              loading={tripExpenseLoading}
              pagination={false}
              summary={allHandOverSummary}
              title={() => 'Hand-Over Summaries'}
            />

            <br />
            <Table
              rowKey="id"
              columns={tripExpenceColumn}
              dataSource={expenses?.items}
              size="small"
              loading={tripExpenseLoading}
              pagination={false}
              summary={expenseSummary}
              title={() => 'Expense Summaries'}
            />
            <br />
            <Table
              rowKey="id"
              columns={tripIncomeColumn}
              dataSource={incomes}
              size="small"
              loading={tripExpenseLoading}
              pagination={false}
              summary={allIncomeSummary}
              title={() => 'Income Summaries'}
            />
          </>
        )}
        {showModal && (
          <ExpenseModal
            show={showModal}
            trip={trip}
            initialExpense={initialExpense}
            onExpenseActionCompleted={onExpenseActionCompleted}
            onClose={onCancel}
          />
        )}
      </div>
      {!printMode && (
        <TripIncome
          trip={trip}
          printMode={printMode}
          loading={loadingIncome}
          incomes={incomes}
          onIncomeActionCompleted={onIncomeActionCompleted}
          fetchIncome={fetchIncome}
          queryFilter={queryFilter}
        />
      )}
      <Typography.Title level={3} className="mt-30">
        Closing Balance: <MoneyText value={tripReportData?.tripReport.closingBalance ?? 0} />
      </Typography.Title>
      <Row className="mb-30">
        {!printMode && (
          <Col lg={{ span: 3, offset: 21 }} md={{ offset: 20 }} sm={{ span: 4 }} xs={{ offset: 14 }}>
            <Tooltip title={isClosedTrip ? PRINT_CLOSING_BOOK_TIP : CLOSE_TRIP_MESSAGE}>
              <Button
                href={`/trips/${trip.id}/closing-book`}
                target="_blank"
                type="primary"
                block
                disabled={!isClosedTrip}
                icon={<PrinterFilled />}
              >
                Closing Book
              </Button>
            </Tooltip>
          </Col>
        )}
      </Row>
    </>
  );
};
