import React from 'react';
import { Typography } from 'antd';
import moment from 'moment';

import { useReservation } from 'src/providers/reservation-provider/ReservationContext';

import 'src/components/search-result-view/styles/styles.less';
import { ITravelSchedule } from 'src/types/travel.type';
import { SearchTripItem } from 'src/screens/search-trip/search-trip-item/SearchTripItem';
import { PBACContainer } from 'src/components/RBAC/RBACContainer';
import { PERMISSIONS } from 'src/types/user.type';

const { Title } = Typography;

export const SearchResultView: React.FC = () => {
  const { searchParams, searchResults, route } = useReservation();

  let formattedDate = '';

  if (searchParams) {
    formattedDate = moment(searchParams.date).format('ddd, Do MMM YYYY');
  }

  return (
    <PBACContainer
      permissions={[PERMISSIONS.WriteReservation]}
    >
      <div className="search-result-container">
        <div>
          <Title className="result-header" level={4}>
            {`${route?.branch?.name} to ${route?.destination?.name} on ${formattedDate}.`}
          </Title>
          <Title className="result-header" level={5}>
            Here are available schedules
          </Title>
        </div>
        <div>
          {searchResults?.map((schedule: ITravelSchedule) => (
            <SearchTripItem
              key={schedule.id}
              price={schedule.travelFare + schedule.serviceCharge}
              time={schedule.trip!.time}
              schedule={schedule}
            />
          ))}
        </div>
      </div>
    </PBACContainer>
  );
};
