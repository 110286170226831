/* eslint-disable max-len */
import { useEffect, useState } from 'react';

import { usePage } from 'src/components/page/Page';
import { WaybillCard } from 'src/screens/dashboard/components/WaybillCard';
import { ActivitiesCard } from 'src/screens/dashboard/components/ActivitiesCard';
import { UpcomingTripsCard } from 'src/screens/dashboard/components/UpcomingTrips';
import { TerminalStatisticsCard } from 'src/screens/dashboard/components/TerminalStatisticsCard';
import { RecentTransactions } from 'src/screens/dashboard/components/RecentTransactions';
import { PBACContainer } from 'src/components/RBAC/RBACContainer';
import { PERMISSIONS } from 'src/types/user.type';

import 'src/screens/dashboard/styles/dashboard.styles.less';
import { SummaryPanel } from 'src/screens/dashboard/components/SummaryPanel';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';
import { useGetTerminalStatstics } from 'src/hooks/useGetTerminalStatistics';
import moment from 'moment';
import { DEFAULT_DATE_FORMAT } from 'src/utilities/date.utils';
import { ITerminalStatQueryFilter } from 'src/types/dashboardQuery.type';

export const DASHBOARD_TEST_ID = 'DASHBOARD_TEST_ID';
const defaultDate = moment().format(DEFAULT_DATE_FORMAT);

export const Dashboard = () => {
  const { setSubTitle, setTitle } = usePage();
  const { activeBranch } = useBranch();
  const [filterQuery, setFilterQuery] = useState<ITerminalStatQueryFilter>({
    branchId: activeBranch?.id!,
    startDate: defaultDate,
    endDate: defaultDate,
  });
  const [{ data, loading }] = useGetTerminalStatstics(filterQuery);

  useEffect(() => {
    setTitle('Dashboard');
    setSubTitle('');
  }, []);

  const onRangeSelected = (start: string | null, end: string | null) => {
    setFilterQuery({
      ...filterQuery,
      startDate: start ?? moment().format(DEFAULT_DATE_FORMAT),
      endDate: end ?? undefined,
    });
  };

  return (
    <div data-testid={DASHBOARD_TEST_ID}>
      <SummaryPanel data={data} loading={loading} onRangeSelected={onRangeSelected} />
      <div className="mid-section">
        <PBACContainer permissions={[PERMISSIONS.ReadWaybill, PERMISSIONS.WriteWaybill, PERMISSIONS.TransferWaybill, PERMISSIONS.CancelWaybill]}>
          <div className="left">
            <WaybillCard />
          </div>
        </PBACContainer>

        <div className="right">
          <TerminalStatisticsCard data={data} loading={loading} />
        </div>
      </div>
      <div className="mid-section">
        <div className="left">
          <ActivitiesCard />
        </div>
        <PBACContainer permissions={[PERMISSIONS.ReadTrip, PERMISSIONS.WriteTrip]}>
          <div className="right">
            <UpcomingTripsCard />
          </div>
        </PBACContainer>
      </div>
      <PBACContainer permissions={[PERMISSIONS.ReadTransactions, PERMISSIONS.WriteTransactions, PERMISSIONS.DeleteTransactions]}>
        <div>
          <RecentTransactions />
        </div>
      </PBACContainer>
    </div>
  );
};
