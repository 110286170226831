import { useParams } from 'react-router-dom';
import { Printable } from 'src/components/print/Print';
import { WaybillDocument } from 'src/components/waybill-document/WaybillDocument';
import { useGetWaybill } from 'src/hooks/useGetWaybill';
import { PBACContainer } from 'src/components/RBAC/RBACContainer';
import { PERMISSIONS } from 'src/types/user.type';

export const PrintWaybill = () => {
  const params = useParams();

  const [{ data: waybill, loading }] = useGetWaybill(parseInt(params.id!, 10));

  return (
    <PBACContainer permissions={[PERMISSIONS.ReadWaybill, PERMISSIONS.WriteWaybill, PERMISSIONS.DeleteWaybill]}>
      <Printable loading={loading}>{waybill && <WaybillDocument waybill={waybill} />}</Printable>;
    </PBACContainer>
  );
};
