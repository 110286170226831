import React, { useState, createContext, useMemo, useEffect, useContext } from 'react';

import { LoadingView } from 'src/components/loading-view/LoadingView';
import { IBranch } from 'src/types/branch.type';
import { useAuth } from 'src/providers/auth-provider/AuthProvider';
import { useGetUserBranches } from 'src/hooks/useGetUserBranches';
import { DEFAULT_BRANCH_KEY } from 'src/constants';
import { useTerminalAuth } from 'src/hooks/useTerminalAuth';
import { setAuthorizationToken } from 'src/services/api';
import { getTerminalToken, setDefaultBranchId, setTerminalToken } from 'src/utilities/storage.utils';
import { useGetTerminalsQuery } from 'src/graphql/queries/get-terminals';
import { GetTerminalsQuery } from 'src/graphql/queries/__generated__/get-terminals.generated';

/**
 * BranchProvider makes the branch information available to the children in the component tree.
 *
 */

export interface IBranchProviderProps {
  children: React.ReactNode | React.ReactNode[];
}

export type BranchProviderValues = {
  activeBranch: IBranch | null;
  allBranches?: GetTerminalsQuery | undefined;
  branches: IBranch[] | null;
  setActiveBranchId: (id: number) => void;
};

const defaultBranch: IBranch = {
  id: 0,
  organizationId: 0,
  name: '',
  publicId: '',
  addressId: 0,
  createdAt: '',
  updatedAt: '',
};

export const BranchContext = createContext<BranchProviderValues>({
  activeBranch: defaultBranch,
  branches: [],
  setActiveBranchId: () => {},
});

export const useBranch = () => {
  if (!BranchContext) {
    throw Error('useBranch can only be used within an BranchContextProvider');
  }

  return useContext(BranchContext);
};

export const BranchProvider = ({ children }: IBranchProviderProps) => {
  const { user, logout } = useAuth();
  const [authTerminal, { loading: authTerminalLoading }] = useTerminalAuth();
  const [{ data: branches, loading }] = useGetUserBranches(user.id as unknown as number);
  const { data: allBranches, loading: terminalLoading } = useGetTerminalsQuery();

  const [activeBranch, setActiveBranch] = useState<IBranch | null>(null);
  const [activeBranchId, setActiveBranchId] = useState<number>();

  const value = useMemo(
    () => ({ activeBranch, setActiveBranchId, branches, allBranches }),
    [activeBranch, setActiveBranchId, branches, allBranches],
  );

  // set default active branch id
  useEffect(() => {
    const authenticateTerminalAccess = async () => {
      if (branches && branches.length) {
        let branchId: number = branches[0].id;
        const defaultBranchId = localStorage.getItem(DEFAULT_BRANCH_KEY);
        if (defaultBranchId) {
          const branch = branches.find((b) => b.id === parseInt(defaultBranchId!, 10));
          if (branch) branchId = branch.id;
        }
        setActiveBranchId(branchId);
      }
    };
    authenticateTerminalAccess();
  }, [branches]);

  useEffect(() => {
    const handleBranchSwitch = async () => {
      if (branches && branches?.length && activeBranchId) {
        const branch = branches.find((b) => b.id === activeBranchId);
        if (branch) {
          let terminalKey = getTerminalToken() ?? undefined;
          if (!terminalKey) {
            terminalKey = (await authTerminal({ params: { branchId: branch.id } }))?.accessToken;
          }

          if (terminalKey) {
            setAuthorizationToken(terminalKey);
            setTerminalToken(terminalKey);
            setActiveBranch(branch);
            setDefaultBranchId(`${branch.id}`);
          } else {
            logout();
          }
        }
      }
    };
    handleBranchSwitch();
  }, [branches, activeBranchId, setActiveBranch]);

  if (loading || authTerminalLoading || terminalLoading || !branches || !activeBranch || !allBranches) {
    return <LoadingView />;
  }

  return <BranchContext.Provider value={value}>{children}</BranchContext.Provider>;
};
