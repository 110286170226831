import { Popconfirm, Table, Typography } from 'antd';

import { LoadingView } from 'src/components/loading-view/LoadingView';
import { ITrip, ITripHandOver } from 'src/types/trip.type';
import { TripHandOverForm } from 'src/screens/trips/components/TripHandOverForm';
import { MoneyText } from 'src/components/topography/MoneyText';
import { isClosed } from 'src/screens/trips/utils/trip-utils';
import { useGetTripHandovers } from 'src/hooks/useGetTripHandovers';
import { useMessage } from 'src/providers/message-provider/MessageProvider';
import { tripHandOvercolumns } from 'src/screens/trips/TripUtils';
import { CloseCircleOutlined } from '@ant-design/icons';
import { useDeleteTripHandOver } from 'src/hooks/useDeleteTripHandOver';
import { PBACContainer } from 'src/components/RBAC/RBACContainer';
import { useAuth } from 'src/providers/auth-provider/AuthProvider';
import { PERMISSIONS } from 'src/types/user.type';

export const TripHandOver = ({ trip }: { trip: ITrip }) => {
  const [{ data: handovers = [], loading }, fetchHandovers] = useGetTripHandovers(trip.id);
  const { user } = useAuth();
  const [deleteHandover, { loading: deleteHandoverLoading }] = useDeleteTripHandOver();
  const { successMessage } = useMessage();

  const onPassengerHandOver = async () => {
    await fetchHandovers(trip.id);
    successMessage({ content: 'Handover record created' });
  };

  const removeHandoveRecord = async (id: number) => {
    const deleted = await deleteHandover(trip.id, id);
    if (deleted) {
      await fetchHandovers(trip.id);
      successMessage({ content: 'Record successfully deleted.' });
    }
  };

  const isClosedTrip = isClosed(trip);

  const cols = [...tripHandOvercolumns];
  if (!isClosedTrip && user.permissions?.includes(PERMISSIONS.WriteTrip)) {
    cols.push({
      fixed: 'right',
      title: 'Action',
      align: 'center',
      render: ({ id }: ITripHandOver) => (
        <Popconfirm
          title="Are you sure you want to delete this record?"
          onConfirm={() => removeHandoveRecord(id)}
          okText="Yes"
          cancelText="No"
        >
          <CloseCircleOutlined style={{ color: '#c0392b' }} />
        </Popconfirm>
      ),
    });
  }
  if (loading) {
    return <LoadingView />;
  }

  const handoverSummary = () => {
    const totlalExpense = handovers.reduce((sum, handover: ITripHandOver) => sum + handover.amount, 0);
    return (
      <Table.Summary fixed>
        <Table.Summary.Row>
          <Table.Summary.Cell index={0}>
            <Typography.Text strong>Total</Typography.Text>
          </Table.Summary.Cell>
          <Table.Summary.Cell index={1}>
            <Typography.Text strong>
              <MoneyText value={totlalExpense} />
            </Typography.Text>
          </Table.Summary.Cell>
        </Table.Summary.Row>
      </Table.Summary>
    );
  };

  return (
    <PBACContainer permissions={[PERMISSIONS.ReadTrip, PERMISSIONS.WriteTrip]}>
      {!isClosedTrip && <TripHandOverForm trip={trip} onHandOverCreated={onPassengerHandOver} handovers={handovers} />}
      <div className="mt-50">
        <Table
          rowKey={(row: ITripHandOver) => row.id}
          columns={cols}
          dataSource={handovers}
          bordered
          loading={loading || deleteHandoverLoading}
          pagination={false}
          summary={handoverSummary}
          scroll={{ x: 500 }}
        />
      </div>
    </PBACContainer>
  );
};
