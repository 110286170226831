/* eslint-disable max-len */
import { useState } from 'react';
import { SwapOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import Table from 'antd/lib/table';

import { LoadingView } from 'src/components/loading-view/LoadingView';
import { IPassenger } from 'src/types/passanger.type';
import { ITrip } from 'src/types/trip.type';
import { TransferPassengerModal } from 'src/screens/trips/components/TransferPassengerModal';
import { useGetTripPassengers } from 'src/hooks/useGetTripPassengers';
import { isClosed, tripPassengerColumns } from 'src/screens/trips/utils/trip-utils';
import { TICKET_STATUS } from 'src/types/ticket.type';
import { useAuth } from 'src/providers/auth-provider/AuthProvider';
import { PERMISSIONS } from 'src/types/user.type';
import { PBACContainer } from 'src/components/RBAC/RBACContainer';

export const TripPassengers = ({ trip }: { trip: ITrip }) => {
  const [showModal, setShowModal] = useState<boolean>(false);
  const [selectedPassenger, setSelectedPassenger] = useState<IPassenger>();

  const [{ data: passengers, loading }, refetch] = useGetTripPassengers(trip.id);
  const { user } = useAuth();

  const isClosedTrip = isClosed(trip);

  const transferPassenger = (passenger: IPassenger) => {
    setSelectedPassenger(passenger);
    setShowModal(true);
  };

  const transferComplete = async () => {
    await refetch(trip.id);
    setSelectedPassenger(undefined);
    setShowModal(false);
  };

  const cols = [...tripPassengerColumns];
  if (!isClosedTrip && (user.permissions?.includes(PERMISSIONS.WriteTrip) || user.permissions?.includes(PERMISSIONS.WritePassenger))) {
    cols.push({
      fixed: 'right',
      title: 'Action',
      align: 'center',
      render: (passenger: IPassenger) => (
        <Button
          icon={<SwapOutlined />}
          type="text"
          onClick={() => transferPassenger(passenger)}
        >
          Transfer
        </Button>
      ),
    });
  }
  if (loading) {
    return <LoadingView />;
  }

  const activePassengers = passengers?.filter((p) => p.ticket?.status === TICKET_STATUS.ISSUED);

  return (
    <PBACContainer permissions={[PERMISSIONS.ReadTrip, PERMISSIONS.WriteTrip, PERMISSIONS.TransferPassenger]}>
      <>
        <Table
          rowKey={(row: IPassenger) => row.id}
          columns={cols}
          dataSource={activePassengers}
          bordered
          loading={loading}
          pagination={false}
          scroll={{ x: 1500 }}
        />
        <TransferPassengerModal open={showModal} passenger={selectedPassenger} trip={trip} onClose={transferComplete} />
      </>
    </PBACContainer>
  );
};
