/* eslint-disable max-len */
import { Badge, Tag } from 'antd';
import { ColumnsType } from 'antd/lib/table';
import moment from 'moment';
import { Link } from 'react-router-dom';

import { MoneyText } from 'src/components/topography/MoneyText';
import { IPassenger } from 'src/types/passanger.type';
import { ISeat } from 'src/types/seat-map.type';
import { ITicket, TICKET_STATUS } from 'src/types/ticket.type';
import { ITravelSchedule } from 'src/types/travel.type';
import { textOrNA } from 'src/utilities/string.utils';

export const getTicketStatusTag = (ticket?: ITicket) => {
  if (ticket?.status === TICKET_STATUS.CANCELLED) return <Tag color="#f50">Cancelled</Tag>;
  if (ticket?.status === TICKET_STATUS.ISSUED) return <Tag color="#87d068">Issued</Tag>;
  if (ticket?.status === TICKET_STATUS.ON_HOLD) return <Tag color="#108ee9">Pending</Tag>;
  return null;
};

export const getTicketStatusBadge = (ticket: ITicket) => {
  if (ticket.status === TICKET_STATUS.CANCELLED) return <Badge status="error" text="cancelled" />;
  if (ticket.status === TICKET_STATUS.ISSUED) return <Badge status="success" text="issued" />;
  if (ticket.status === TICKET_STATUS.ON_HOLD) return <Badge status="processing" text="pending" />;
  return null;
};

export const passengerColumns: ColumnsType<IPassenger> = [
  {
    title: 'PNR',
    dataIndex: 'pnr',
    key: 'pnr',
    width: 180,
    fixed: 'left',
    render: (fullName: string) => fullName,
  },
  {
    title: 'Full Name',
    dataIndex: 'fullName',
    key: 'fullName',
    render: (fullName: string) => fullName,
  },
  {
    title: 'Status',
    render: (p: IPassenger) => getTicketStatusBadge(p.ticket!),
  },
  {
    title: 'Type',
    dataIndex: 'type',
    key: 'type',
  },
  {
    title: 'Destination',
    dataIndex: 'travelSchedule',
    key: 'travelSchedule',
    render: (travelSchedule: ITravelSchedule) => travelSchedule.route?.destination?.name,
  },
  {
    title: 'Travel Fare',
    dataIndex: 'ticket',
    key: 'ticket',
    render: (ticket: ITicket) => <MoneyText value={ticket.charge} />,
  },
  {
    title: 'Amount Paid',
    dataIndex: 'ticket',
    key: 'ticket',
    render: (ticket: ITicket) =>
      ticket.status === TICKET_STATUS.ISSUED && <MoneyText value={ticket.charge + ticket.serviceCharge} />,
  },
  {
    title: 'Trip',
    dataIndex: 'travelSchedule',
    key: 'trip',
    render: (travelSchedule: ITravelSchedule) => (
      <Link to={`/trips/${travelSchedule.trip?.id}`}>
        {`${travelSchedule.trip?.destination?.code} | ${moment(`${travelSchedule.trip?.date} ${travelSchedule.trip?.time}`).format('hh:mm A')}`}
      </Link>
    ),
  },
  {
    title: 'Seat No',
    dataIndex: 'seat',
    key: 'seat',
    width: 100,
    render: (seat?: ISeat) => textOrNA(seat?.number),
  },
  {
    title: 'Issued By',
    dataIndex: 'ticket',
    key: 'ticket',
    render: (ticket: ITicket) => `${ticket.ticketer?.firstName} ${ticket.ticketer?.lastName}`,
  },
  {
    title: 'Date Issued',
    dataIndex: 'ticket',
    key: 'ticket',
    render: (ticket: ITicket) => <span>{moment(ticket.createdAt).format('ddd, Do MMM YYYY')}</span>,
  },
];
