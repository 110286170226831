/* eslint-disable no-console */
import { Card, Descriptions, Space, Typography } from 'antd';
import React, { useEffect } from 'react';
import { usePage } from 'src/components/page/Page';
import { USER_ROLE, PERMISSIONS } from 'src/types/user.type';
import { getRoleTags } from 'src/screens/users/UserUtils';
import { BranchForm } from 'src/screens/users/user-details/forms/branch.form';
import { RolesForm } from 'src/screens/users/user-details/forms/roles.form';
import { useParams } from 'react-router-dom';
import { useGetUserQuery } from 'src/graphql/queries/get-user';
import { LoadingView } from 'src/components/loading-view/LoadingView';
import { useGetUserBranchesQuery } from 'src/graphql/queries/get-user-branches';
import { PBACContainer } from 'src/components/RBAC/RBACContainer';
import { useGeRolesQuery } from 'src/graphql/queries/get-roles';

export const UserDetails = () => {
  const { setSubTitle, setTitle } = usePage();
  const { id } = useParams();
  const { data, loading, refetch } = useGetUserQuery(id!);
  const {
    data: userBranchData,
    loading: userBranchLoading,
    refetch: userBranchRefresh,
  } = useGetUserBranchesQuery(+id!);

  const { data: rolesData, loading: rolesLoading } = useGeRolesQuery();

  useEffect(() => {
    setTitle('Users');
    setSubTitle('User information');
  }, []);

  if (loading || !data || userBranchLoading || !userBranchData || rolesLoading) {
    return <LoadingView />;
  }

  return (
    <PBACContainer permissions={[PERMISSIONS.ReadUser, PERMISSIONS.WriteUser, PERMISSIONS.DeleteUser]}>
      <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
        <Card bordered={false} title={<Typography.Title level={5}>User Information</Typography.Title>}>
          <Descriptions layout="horizontal" column={1} labelStyle={{ width: '130px', fontWeight: 'bold' }}>
            <Descriptions.Item label="Name">
              {data.getUser.firstName} {data.getUser.lastName}
            </Descriptions.Item>
            <Descriptions.Item label="Email">{data.getUser.email}</Descriptions.Item>
            <Descriptions.Item label="Phone number">{data.getUser.phone}</Descriptions.Item>
            <Descriptions.Item label="Roles">{getRoleTags(data.getUser.roles as USER_ROLE[])}</Descriptions.Item>
          </Descriptions>
        </Card>
        <Card bordered={false} title={<Typography.Title level={5}>Roles</Typography.Title>}>
          <RolesForm roles={data.getUser.roles} refetch={refetch} systemRoles={rolesData} />
        </Card>
        <Card bordered={false} title={<Typography.Title level={5}>Branch</Typography.Title>}>
          <BranchForm
            branches={
              userBranchData.getUserBranches as {
                name: string;
                id: string;
              }[]
            }
            refetch={userBranchRefresh}
          />
        </Card>
      </Space>
    </PBACContainer>
  );
};
