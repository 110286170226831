import React, { useState, useEffect } from 'react';
import { Alert, Layout, Form, Input, Checkbox, Button } from 'antd';
import { LockOutlined, UserOutlined, QuestionOutlined, GlobalOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';

import 'src/screens/login/styles/login.less';
import { AUTH_TOKEN_KEY, PERMIFY_USER_KEY, TERMINAL_KEY } from 'src/constants';
import { useLoginUserMutation } from 'src/graphql/mutations/login-user';
import { setAuthorizationToken } from 'src/services/api';

const { Content, Footer } = Layout;

const Login: React.FC = () => {
  const [error, setError] = useState<string>('');
  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [loginUserMutation, { loading: loginLoading }] = useLoginUserMutation();

  const showError = (message: string) => {
    setError(message);
    setTimeout(() => {
      setError('');
    }, 3000);
  };

  useEffect(() => {
    localStorage.removeItem(AUTH_TOKEN_KEY);
    localStorage.removeItem(TERMINAL_KEY);
    localStorage.removeItem(PERMIFY_USER_KEY);
  }, []);

  const handleLogin = async (email: string, password: string) => {
    try {
      const response = await loginUserMutation({
        variables: {
          input: {
            email,
            password,
          },
        },
      });
      localStorage.setItem(AUTH_TOKEN_KEY, response.data?.loginUser.accessToken as string);
      setAuthorizationToken(response.data?.loginUser.accessToken as string);
      form.resetFields();
      navigate('/dashboard');
    } catch (e) {
      showError('Incorrect email/password!');
    }
  };

  const onFormSubmit = async () => {
    try {
      const values = await form.validateFields();
      const { email, password } = values;
      handleLogin(email, password);
    } catch (e) {
      showError('There was an error in logging in!');
    }
  };
  return (
    <Layout className="login-main">
      <div className="login-top-icon">
        <GlobalOutlined />
      </div>
      <div className="login-bottom-icon">
        <QuestionOutlined />
      </div>

      <Content className="login-content">
        <img
          alt="logo"
          width={200}
          /* eslint-disable-next-line max-len */
          src={process.env.REACT_APP_LOGO}
        />
        {error && <Alert message={error} className="login-alert" type="error" />}
        <Form
          className="login-form"
          form={form}
          initialValues={{ remember: true }}
          title="form"
          onFinish={onFormSubmit}
        >
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                message: 'Please input your email address!',
              },
            ]}
          >
            <Input prefix={<UserOutlined />} placeholder="Enter Email address" className="login-input" />
          </Form.Item>

          <Form.Item name="password" rules={[{ required: true, message: 'Please input your password!' }]}>
            <Input.Password prefix={<LockOutlined />} placeholder="Enter Password" className="login-input" />
          </Form.Item>

          <div className="login-form-remember-me">
            <Form.Item name="remember" valuePropName="checked">
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <Link to="/login">Forgot Password?</Link>
          </div>

          <Button type="primary" htmlType="submit" loading={loginLoading} disabled={loginLoading}>
            Submit
          </Button>
        </Form>
      </Content>

      <Footer className="login-footer" />
    </Layout>
  );
};

export { Login };
