import React from 'react';
import { useParams } from 'react-router-dom';
import { Printable } from 'src/components/print/Print';
import { useGetTrip } from 'src/hooks/getTrips';
import { TripClosingBook } from 'src/screens/trips/components/TripClosingBook';
import { PBACContainer } from 'src/components/RBAC/RBACContainer';
import { PERMISSIONS } from 'src/types/user.type';

export const ClosingBook: React.FC = () => {
  const params = useParams();

  const [{ data, loading }] = useGetTrip({ params: { tripId: parseInt(params.id!, 10) } });

  return (
    <PBACContainer permissions={[PERMISSIONS.ReadTrip, PERMISSIONS.WriteTrip]}>
      <Printable styles={{ padding: '0 5%' }} loading={loading || !data}>
        {data && <TripClosingBook trip={data} printMode />}
      </Printable>
    </PBACContainer>
  );
};
