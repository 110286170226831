/* eslint-disable max-len */
import { Button, Table, Card } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';

import 'src/screens/vehicle-class/styles/busClassManagement.styles.less';
import { usePage } from 'src/components/page/Page';
import { useMessage } from 'src/providers/message-provider/MessageProvider';
import { vehicleClassConfigColumns } from 'src/screens/vehicle-class/VehicleClassUtils';
import {
  VehicleClassConfig,
  useFindVehicleClassConfigQuery,
} from 'src/graphql/queries/find-vehicle-class-configuration';
import { VehicleClassConfigModal } from 'src/screens/vehicle-class/vehicle-class-modal/VehicleClassConfigModal';
import { PBACContainer } from 'src/components/RBAC/RBACContainer';
import { useAuth } from 'src/providers/auth-provider/AuthProvider';
import { PERMISSIONS } from 'src/types/user.type';

export const VehicleClassConfiguration = () => {
  const { setSubTitle, setTitle } = usePage();
  const { data, loading, refetch } = useFindVehicleClassConfigQuery();
  const { successMessage } = useMessage();
  const { user } = useAuth();
  const [showVehicleClassConfigModal, setShowVehicleClassConfigModal] = useState<boolean>(false);
  const [selectedVehicleClassConfig, setSelectedVehicleClassConfig] = useState<VehicleClassConfig>();

  useEffect(() => {
    setTitle('Vehicle Management');
    setSubTitle('Class Configuration');
  }, []);

  const closeVehicleClassConfigModal = () => {
    setSelectedVehicleClassConfig(undefined);
    setShowVehicleClassConfigModal(false);
  };

  const showEditVehicleClassConfigModal = (id: string) => {
    const vehicleClassConfig = data?.findVehicleClassConfigs.find((vc) => vc.id === id);
    setSelectedVehicleClassConfig(vehicleClassConfig);
    setShowVehicleClassConfigModal(true);
  };

  const onVehicleClassConfigActionComplete = async () => {
    await refetch();
    closeVehicleClassConfigModal();
    successMessage({ content: 'Request successfully completed', duration: 2.5 });
  };

  const columns = [...vehicleClassConfigColumns];
  if (user?.permissions?.includes(PERMISSIONS.WriteVehicleClass)) {
    columns.push({
      title: 'Action',
      dataIndex: 'id',
      key: 'action',
      render: (id: string) => (
        <EditOutlined onClick={() => showEditVehicleClassConfigModal(id)} className="more-btn" href="#" />
      ),
      fixed: 'right',
      align: 'center',
      width: 100,
    });
  }

  return (
    <PBACContainer permissions={[PERMISSIONS.ReadVehicleClass, PERMISSIONS.WriteVehicleClass]}>
      <>
        <Card
          bordered={false}
          extra={[
            <Button
              key="add-bus-class"
              type="primary"
              icon={<PlusOutlined />}
              disabled={!user?.permissions?.includes(PERMISSIONS.WriteVehicleClass)}
              onClick={() => setShowVehicleClassConfigModal(true)}
            >
              Add New
            </Button>,
          ]}
        >
          <Table
            rowKey={(config: VehicleClassConfig) => config.id}
            columns={columns}
            dataSource={data?.findVehicleClassConfigs}
            loading={loading}
          />
        </Card>
        <VehicleClassConfigModal
          show={showVehicleClassConfigModal}
          onClose={closeVehicleClassConfigModal}
          onActionComplete={onVehicleClassConfigActionComplete}
          defaultVehicleClassConfig={selectedVehicleClassConfig}
        />
      </>
    </PBACContainer>
  );
};
