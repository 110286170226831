import { Button, Card, Form, InputNumber, Select } from 'antd';
import { useForm } from 'antd/lib/form/Form';

import { INewHandoverParams, ITrip, ITripHandOver } from 'src/types/trip.type';
import { onPassengerFilter } from 'src/utilities/helpers.utils';
import { useGetTripPassengers } from 'src/hooks/useGetTripPassengers';
import { TICKET_STATUS } from 'src/types/ticket.type';
import { usePostCreateHandover } from 'src/hooks/usePostCreateHandover';
import { useAuth } from 'src/providers/auth-provider/AuthProvider';
import { PERMISSIONS } from 'src/types/user.type';

export const TripHandOverForm = ({
  trip,
  handovers,
  onHandOverCreated,
}: {
  trip: ITrip;
  handovers: ITripHandOver[];
  onHandOverCreated: (handovers: ITripHandOver[]) => void;
}) => {
  const [form] = useForm();
  const { user } = useAuth();

  const [{ data: passengers = [], loading }] = useGetTripPassengers(trip.id);
  const [saveHandover, { loading: createHandoverLoading }] = usePostCreateHandover();

  const saveHandoverRecord = async (params: INewHandoverParams[]) => {
    const result = await saveHandover(trip.id, params);
    if (result) {
      onHandOverCreated(result);
      form.resetFields();
    }
  };

  const onFormSubmited = (values: { amount: number; passangerIds: number[] }) => {
    const newHandoverParams: INewHandoverParams[] = values.passangerIds.map((passengerId) => ({
      passengerId,
      amount: values.amount,
    }));
    saveHandoverRecord(newHandoverParams);
  };

  // We don't want to display passengers that have already been added
  const filteredHandovers = passengers
    ?.filter((p) => p.ticket?.status === TICKET_STATUS.ISSUED)
    ?.filter((passenger) => {
      return !handovers.some((handover) => handover.passenger?.id === passenger.id);
    });

  return (
    <Card>
      <Form
        size="large"
        wrapperCol={{
          xs: { span: 8 },
          sm: { span: 8 },
        }}
        onFinish={onFormSubmited}
        layout="vertical"
        form={form}
      >
        <Form.Item
          label="Passengers"
          name="passangerIds"
          rules={[
            {
              required: true,
              message: 'Please select at least one passenger',
            },
          ]}
        >
          <Select
            mode="multiple"
            allowClear
            style={{ width: '100%' }}
            placeholder="Please select passengers"
            disabled={!user.permissions?.includes(PERMISSIONS.WriteTrip)}
            filterOption={(input: string, option?: { value: number }) => {
              return onPassengerFilter(passengers, input, option);
            }}
          >
            {filteredHandovers.map((passanger) => (
              <Select.Option key={passanger.id} value={passanger.id}>
                {passanger.fullName}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item name="amount" label="Amount" rules={[{ required: true, message: 'Please input an amount' }]}>
          <InputNumber className="fw" disabled={!user.permissions?.includes(PERMISSIONS.WriteTrip)} />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!user.permissions?.includes(PERMISSIONS.WriteTrip) || loading || createHandoverLoading}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};
