import { Card, Col, Row, Typography, Descriptions } from 'antd';
import { useParams } from 'react-router-dom';
import { LoadingView } from 'src/components/loading-view/LoadingView';
import { useGetTransactionById } from 'src/graphql/queries/get-transaction-by-id';
import { MoneyText } from 'src/components/topography/MoneyText';
import { PBACContainer } from 'src/components/RBAC/RBACContainer';
import { PERMISSIONS } from 'src/types/user.type';

export const TransactionDetails = () => {
  const { id } = useParams();
  const { data: transactionData, loading } = useGetTransactionById(id!);

  if (loading) return <LoadingView />;

  const transaction = transactionData?.findTransactionById;

  return (
    <PBACContainer permissions={[PERMISSIONS.ReadTransactions]}>
      <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]}>
        <Col span={12}>
          <Card bordered={false} title={<Typography.Title level={5}>Transaction Details</Typography.Title>}>
            <Descriptions layout="horizontal" column={1} labelStyle={{ width: '130px' }}>
              <Descriptions.Item label="Transaction ID">{transaction?.id}</Descriptions.Item>
              <Descriptions.Item label="Group Tag">{transaction?.groupTag}</Descriptions.Item>
              <Descriptions.Item label="Reference">{transaction?.reference}</Descriptions.Item>
              <Descriptions.Item label="Status">{transaction?.status}</Descriptions.Item>
              <Descriptions.Item label="Payment Method">{transaction?.paymentMethod}</Descriptions.Item>
              <Descriptions.Item label="Type">{transaction?.type}</Descriptions.Item>
              <Descriptions.Item label="Created At">
                {transaction?.createdAt ? new Date(transaction.createdAt).toLocaleString() : 'N/A'}
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>

        <Col span={12}>
          <Card bordered={false} title={<Typography.Title level={5}>Ticketer Details</Typography.Title>}>
            <Descriptions layout="horizontal" column={1} labelStyle={{ width: '130px' }}>
              <Descriptions.Item label="Full Name">
                {transaction?.ticketer?.firstName} {transaction?.ticketer?.lastName}
              </Descriptions.Item>
              <Descriptions.Item label="Phone">{transaction?.ticketer?.phone}</Descriptions.Item>
              <Descriptions.Item label="Email">{transaction?.ticketer?.email}</Descriptions.Item>
              <Descriptions.Item label="Roles">{transaction?.ticketer?.roles.join(', ')}</Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>

        <Col span={12}>
          <Card bordered={false} title={<Typography.Title level={5}>Financial Details</Typography.Title>}>
            <Descriptions layout="horizontal" column={1} labelStyle={{ width: '150px' }}>
              <Descriptions.Item label="Amount">
                <MoneyText value={transaction?.amount ?? 0} />
              </Descriptions.Item>
              <Descriptions.Item label="Total Amount">
                <MoneyText value={transaction?.totalAmount ?? 0} />
              </Descriptions.Item>
              <Descriptions.Item label="Service Charge">
                <MoneyText value={transaction?.serviceCharge ?? 0} />
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
      </Row>
    </PBACContainer>
  );
};
