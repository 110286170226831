import { Button, Card, DatePicker, Form, Input, message, Select, TimePicker } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useState } from 'react';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';
import { TripService } from 'src/services/trip.service';
import { INewTripEventParams, ITrip, ITripEvent, ITripEventType } from 'src/types/trip.type';
import { useAuth } from 'src/providers/auth-provider/AuthProvider';
import { PERMISSIONS } from 'src/types/user.type';

export const TripEventForm = ({
  trip,
  eventTypes,
  onEventCreated,
}: {
  trip: ITrip;
  eventTypes: ITripEventType[];
  onEventCreated: (event: ITripEvent) => void;
}) => {
  const [form] = useForm();
  const { activeBranch } = useBranch();
  const { user } = useAuth();

  const [loading, setLoading] = useState<boolean>();

  const saveEventRecord = async (params: INewTripEventParams) => {
    setLoading(true);

    try {
      const newEvent = await TripService.createTripEvent(trip.id, params);
      if (onEventCreated) {
        onEventCreated(newEvent);
      }
      form.resetFields();
    } catch (e) {
      message.error('Error saving event record');
    }

    setLoading(false);
  };

  const onFormSubmited = (values: any) => {
    const date = values.date.format('YYYY-MM-DD');
    const time = values.time.format('hh:mm');
    const newEventParams: INewTripEventParams = {
      ...values,
      date: `${date} ${time}`,
      branchId: activeBranch?.id,
    };
    saveEventRecord(newEventParams);
  };

  return (
    <Card>
      <Form
        wrapperCol={{
          xs: { span: 8 },
          sm: { span: 8 },
        }}
        onFinish={onFormSubmited}
        layout="vertical"
        form={form}
      >
        <Form.Item
          label="Issue"
          name="typeId"
          rules={[
            {
              required: true,
              message: 'Please select an event issue type',
            },
          ]}
        >
          <Select disabled={!user.permissions?.includes(PERMISSIONS.WriteTrip)}>
            {eventTypes.map((eventType) => (
              <Select.Option key={eventType.id} value={eventType.id}>
                {eventType.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>

        <Form.Item label="Date & Time" required>
          <Input.Group compact>
            <Form.Item name="date" noStyle rules={[{ required: true, message: 'Date is required' }]}>
              <DatePicker
                placeholder="Select a date"
                style={{ width: '50%' }}
                disabled={!user.permissions?.includes(PERMISSIONS.WriteTrip)}
              />
            </Form.Item>
            <Form.Item name="time" noStyle rules={[{ required: true, message: 'Time is required' }]}>
              <TimePicker
                showSecond={false}
                placeholder="Select a time"
                style={{ width: '50%' }}
                disabled={!user.permissions?.includes(PERMISSIONS.WriteTrip)}
              />
            </Form.Item>
          </Input.Group>
        </Form.Item>

        <Form.Item
          name="description"
          label="Description"
          rules={[{ required: true, message: 'Please input a description' }]}
        >
          <Input.TextArea showCount maxLength={100} disabled={!user.permissions?.includes(PERMISSIONS.WriteTrip)} />
        </Form.Item>

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!user.permissions?.includes(PERMISSIONS.WriteTrip) || loading}
          >
            Save
          </Button>
        </Form.Item>
      </Form>
    </Card>
  );
};
