import { FC } from 'react';
import QRCode from 'react-qr-code';
import logo from 'src/icons/Logo.png';

import 'src/components/passenger-ticket/styles/passengerTicket.style.less';
import { ITicket } from 'src/types/ticket.type';
import { Divider, Tag, Typography } from 'antd';
import moment from 'moment';
import { MoneyText } from 'src/components/topography/MoneyText';
import { useGetTrip } from 'src/hooks/getTrips';
import { LoadingView } from 'src/components/loading-view/LoadingView';

interface PassengerTicketProps {
  ticket: ITicket;
}

export const PassengerTicket: FC<PassengerTicketProps> = ({ ticket }) => {
  const { departureDate, departureTime, trip: ticketTrip } = ticket;

  const [{ data: trip, loading }] = useGetTrip({ params: { tripId: ticketTrip!.id } });

  const dateTime = moment(`${departureDate} ${departureTime}`);

  if (loading) return <LoadingView />;

  return (
    <div className="passenger-ticket-container">
      <div className="logo">
        <img src={logo} alt="company logo" />
      </div>
      <Typography.Text className="mt-20">OKEYSON INVESTMENT SERVICES LTD.</Typography.Text>
      <div className="qr-code">
        <QRCode size={45} viewBox="0 0 45 45" value={ticket.passenger?.pnr ?? 'invalid'} />
      </div>
      <Tag style={{ fontSize: 10 }}>Passenger Ticket</Tag>
      <Divider />
      <div className="info-row">
        <Typography.Text className="info-text">REFERENCE NO:</Typography.Text>
        <Typography.Text className="info-p">{ticket.reservationId}</Typography.Text>
      </div>
      <div className="info-row">
        <Typography.Text className="info-text">NAME OF PASSENGER:</Typography.Text>
        <Typography.Text className="info-p">{ticket.passenger?.fullName}</Typography.Text>
      </div>
      <div className="info-row">
        <Typography.Text className="info-text">SEAT NO.:</Typography.Text>
        <Typography.Text className="info-p">{ticket.passenger?.seat?.number}</Typography.Text>
      </div>
      <div className="info-row">
        <Typography.Text className="info-text">VEHICLE NUMBER:</Typography.Text>
        <Typography.Text className="info-p">{trip?.virtualVehicleNumber}</Typography.Text>
      </div>
      <div className="info-row">
        <Typography.Text className="info-text">DEPARTURE DATE:</Typography.Text>
        <Typography.Text className="info-p">{dateTime.format('Do MMM, YYYY')}</Typography.Text>
      </div>
      <div className="info-row">
        <Typography.Text className="info-text">DEPARTURE TIME:</Typography.Text>
        <Typography.Text className="info-p">{dateTime.format('hh:mm a')}</Typography.Text>
      </div>
      <div className="info-row">
        <Typography.Text className="info-text">DEPARTURE TERMINAL:</Typography.Text>
        <Typography.Text className="info-p">{ticket.route?.branch?.name}</Typography.Text>
      </div>
      <div className="info-row">
        <Typography.Text className="info-text">DESTINATION:</Typography.Text>
        <Typography.Text className="info-p">{ticket.route?.destination?.name}</Typography.Text>
      </div>
      <div className="info-row">
        <Typography.Text className="info-text">TOTAL PAID:</Typography.Text>
        <Typography.Text className="info-p">
          <MoneyText value={ticket.charge + ticket.serviceCharge} />
        </Typography.Text>
      </div>
      <div className="info-line">
        <Typography.Text>Contact Customer Service</Typography.Text>
        <div className="info-line-tel">
          <Typography.Text>08064650704, 08023770939, 07013645414, 07065598434</Typography.Text>
        </div>
      </div>
      <Divider />
      <Typography.Text>
        THIS TICKET IS NOT TRANSFERABLE CIRCUMSTANCES & NO REFUND OF MONEY AFTER PAYMENT.
      </Typography.Text>
    </div>
  );
};
