/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Card, Button, message, Table } from 'antd';
import moment from 'moment';
import { useSearchParams } from 'react-router-dom';

import 'src/screens/travel-schedule/styles/travelSchedule.style.less';
import { usePage } from 'src/components/page/Page';
import { PlusOutlined } from '@ant-design/icons';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';
import { ITrip, TRIP_STATUS } from 'src/types/trip.type';
import { TripModal } from 'src/screens/trips/trip-modal/TripModal';
import { TripOptions } from 'src/screens/trips/components/TripOptions';
import { UseGetTripsQueryParams, useGetTrips } from 'src/hooks/useGetTrips';
import { tripColumns } from 'src/screens/trips/utils/trip-utils';
import { DEFAULT_DATE_FORMAT } from 'src/utilities/date.utils';
import { TripSearch } from 'src/screens/trips/components/TripSearch';
import { PBACContainer } from 'src/components/RBAC/RBACContainer';
import { PERMISSIONS } from 'src/types/user.type';

export const TRIP_TEST_ID = 'TRIP_TEST_ID';

export const Trips: React.FC = () => {
  const { setSubTitle, setTitle } = usePage();
  const { activeBranch } = useBranch();
  const [searchParams, setSearchParams] = useSearchParams();

  const [showModal, setShowModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedTrip, setSelectedTrip] = useState<ITrip>();
  const [filterQuery, setFilterQuery] = useState<UseGetTripsQueryParams>({
    branchId: activeBranch?.id,
    startDate: searchParams.get('startDate') ?? moment().format(DEFAULT_DATE_FORMAT),
    endDate: searchParams.get('endDate') ?? moment().format(DEFAULT_DATE_FORMAT),
    status: (searchParams.get('status') as TRIP_STATUS) ?? TRIP_STATUS.OPEN,
    page: searchParams.get('page') ? Number(searchParams.get('page')) : 1,
    offset: searchParams.get('offset') ? Number(searchParams.get('offset')) : 50,
    destinationId: searchParams.get('destinationId') ? Number(searchParams.get('destinationId')) : undefined,
  });

  const [{ data, loading }, fetchTrips] = useGetTrips({ params: filterQuery });

  useEffect(() => {
    setTitle('Trips');
    setSubTitle('');
  }, []);

  useEffect(() => {
    fetchTrips({ params: filterQuery });
  }, [filterQuery]);

  const onClose = async () => {
    setSelectedTrip(undefined);
    setShowModal(false);
  };

  const onTripActionCompleted = async () => {
    await fetchTrips({ params: filterQuery });
    message.success(`Trip successfully ${selectedTrip ? 'updated' : 'created'}`);
    onClose();
  };

  const onEditTrip = (trip: ITrip) => {
    setSelectedTrip(trip);
    setShowModal(true);
    setIsEdit(true);
  };

  const onPageChange = (currentPage: number, newPageSize: number) => {
    setFilterQuery({ ...filterQuery, page: currentPage, offset: newPageSize });
    setSearchParams({ ...Object.fromEntries(searchParams), page: currentPage.toString(), offset: newPageSize.toString() });
  };

  const cols = [...tripColumns];
  cols.push({
    title: 'Action',
    dataIndex: 'id',
    align: 'center',
    fixed: 'right',
    width: 100,
    render: (id: number, trip: ITrip) => (
      <TripOptions tripId={id} onEdit={() => onEditTrip(trip)} editable={!!trip.closedAt} />
    ),
  });

  return (
    <div>
      <PBACContainer permissions={[PERMISSIONS.ReadTrip, PERMISSIONS.WriteTrip]}>
        <TripSearch onApply={setFilterQuery} />
        <Card
          key="TTIP_SCHEDULE_KEY"
          data-testid={TRIP_TEST_ID}
          bordered={false}
          extra={[
            <PBACContainer permissions={[PERMISSIONS.WriteTrip]}>
              <Button
                key="add-schedule-key"
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => {
                  setSelectedTrip(undefined);
                  setShowModal(true);
                  setIsEdit(false);
                }}
              >
                Add New
              </Button>
            </PBACContainer>,
          ]}
        >
          <Table
            rowKey={(row: ITrip) => row.id}
            columns={cols}
            dataSource={data?.items}
            bordered
            loading={loading}
            pagination={{
              pageSize: data?.meta.offset,
              current: data?.meta.page,
              onChange: onPageChange,
              showSizeChanger: true,
              total: data?.meta.totalItems,
            }}
            scroll={{ x: 1500 }}
          />
        </Card>
        {showModal && (
          <TripModal
            show={showModal}
            onTripActionCompleted={onTripActionCompleted}
            onClose={onClose}
            initialTrip={selectedTrip}
            isEdit={isEdit}
          />
        )}
      </PBACContainer>

    </div>
  );
};
