import { MutationTuple, gql } from '@apollo/client';
import { useMutationErrorNotification } from 'src/graphql/hooks/useMutationErrorNotification';
import {
  CreateOrEditReservationMutation,
  CreateOrEditReservationMutationVariables,
} from 'src/graphql/mutations/__generated__/create-or-edit-booking.generated';

export type CreateReservationResponseTansaction =
  CreateOrEditReservationMutation['createOrEditReservation']['transaction'];
export type CreateReservationResponseTickets = CreateOrEditReservationMutation['createOrEditReservation']['tickets'];
export type CreateReservationResponsePassengers =
  CreateOrEditReservationMutation['createOrEditReservation']['passengers'];
export type CreateReservationResponseReservation =
  CreateOrEditReservationMutation['createOrEditReservation']['reservation'];

const CREATE_RESERVATION_MUTATION = gql`
  mutation CreateOrEditReservation($input: CreateReservationInput!, $provider: String) {
    createOrEditReservation(input: $input, provider: $provider) {
      passengers {
        fullName
        id
        pnr
        type
      }
      tickets {
        passengerId
        tripId
        id
        charge
        serviceCharge
      }
      reservation {
        id
        reference
      }
      transaction {
        id
        totalAmount
        amount
        serviceCharge
        paymentMethod
        processedAt
      }
    }
  }
`;

export const useCreateReservationnMutation = (): MutationTuple<
  CreateOrEditReservationMutation,
  CreateOrEditReservationMutationVariables
> =>
  useMutationErrorNotification<CreateOrEditReservationMutation, CreateOrEditReservationMutationVariables>(
    CREATE_RESERVATION_MUTATION,
  );
