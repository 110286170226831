import { Card, Col, Row, Typography, Descriptions } from 'antd';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { MoneyText } from 'src/components/topography/MoneyText';
import { LoadingView } from 'src/components/loading-view/LoadingView';
import { useGetTicket } from 'src/hooks/useGetTicket';
import { getTicketStatusTag } from 'src/screens/passengers/utils/Passenger.utils';
import { PBACContainer } from 'src/components/RBAC/RBACContainer';
import { PERMISSIONS } from 'src/types/user.type';

export const PASSENGER_DETAILS_PAGE_TEST_ID = 'PASSENGER_DETAILS_PAGE_TEST_ID';

export const PassengerDetails = () => {
  const { id } = useParams();
  const [{ data: ticket, loading }] = useGetTicket(parseInt(id!, 10));

  if (loading) return <LoadingView />;

  return (
    <PBACContainer permissions={[PERMISSIONS.ReadPassenger]}>
      <Row gutter={[16, { xs: 8, sm: 16, md: 24, lg: 32 }]} data-testid={PASSENGER_DETAILS_PAGE_TEST_ID}>
        <Col span={12}>
          <Card bordered={false} title={<Typography.Title level={5}>Passenger Details</Typography.Title>}>
            <Descriptions layout="horizontal" column={1} labelStyle={{ width: '130px' }}>
              <Descriptions.Item label="Full Name">{ticket?.passenger?.fullName}</Descriptions.Item>
              <Descriptions.Item label="Age">{ticket?.passenger?.age}</Descriptions.Item>
              <Descriptions.Item label="Gender">{ticket?.passenger?.gender}</Descriptions.Item>
              <Descriptions.Item label="Seat Number">{ticket?.passenger?.seat?.number}</Descriptions.Item>
              <Descriptions.Item label="Phone Number">{ticket?.passenger?.phone}</Descriptions.Item>
              <Descriptions.Item label="Blood Group">{ticket?.passenger?.bloodGroup}</Descriptions.Item>
              <Descriptions.Item label="Next in Kin name">
                {ticket?.passenger?.nextKinFirstName} {ticket?.passenger?.nextKinLastName}
              </Descriptions.Item>
              <Descriptions.Item label="Next in Kin Email">{ticket?.passenger?.nextKinEmail}</Descriptions.Item>
              <Descriptions.Item label="Next in Kin Phone">{ticket?.passenger?.nextKinPhone}</Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col span={12}>
          <Card bordered={false} title={<Typography.Title level={5}>Reservation Details</Typography.Title>}>
            <Descriptions layout="horizontal" column={1} labelStyle={{ width: '130px' }}>
              <Descriptions.Item label="Departure Date">
                {moment(ticket?.trip?.date).format('ddd Do MMM, YYYY')}
              </Descriptions.Item>
              <Descriptions.Item label="Departure Time">
                {moment(ticket?.trip?.time, 'HH:mm').format('h:mm a')}
              </Descriptions.Item>
              <Descriptions.Item label="Destination">
                {ticket?.route?.destination?.name}({ticket?.route?.destination?.code})
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
        <Col span={12}>
          <Card bordered={false} title={<Typography.Title level={5}>Ticket Information</Typography.Title>}>
            <Descriptions layout="horizontal" column={1} labelStyle={{ width: '150px' }}>
              <Descriptions.Item label="Ticket status">{getTicketStatusTag(ticket)}</Descriptions.Item>
              <Descriptions.Item label="Travel Fare">
                <MoneyText value={ticket?.charge ?? 0} />
              </Descriptions.Item>
            </Descriptions>
          </Card>
        </Col>
      </Row>
    </PBACContainer>
  );
};
