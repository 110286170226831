import React, { useState, createContext, useMemo, useEffect, useContext, useCallback } from 'react';
import { Result } from 'antd';
import { Link, NavigateOptions, useNavigate } from 'react-router-dom';

import { LoadingView } from 'src/components/loading-view/LoadingView';
import { AUTH_TOKEN_KEY } from 'src/constants';
import { setAuthorizationToken } from 'src/services/api';
import { usePermify } from '@permify/react-role';
import { clearSessionToken, clearTerminalToken } from 'src/utilities/storage.utils';
import { USER_ROLE } from 'src/types/user.type';
import { jwtDecode } from 'jwt-decode';
import { AuthUser, useLazyAuthenticatedUserQuery } from 'src/graphql/queries/get-authenticated-user';

/**
 * AuthProvider will load the token info.
 *
 * If the token is not available, an unauthorised view will be rendered.
 *
 * If the token is avaibale, the provider will make a request
 * for the authorised user, and make the user information available to it's children.
 * If the token is no longer valid, an unauthorised page will be displayed.
 *
 */

export interface DecodedToken {
  permissions: string[];
}

export interface IAuthProviderProps {
  children: React.ReactNode | React.ReactNode[];
  // Amadosi (2022-08-14) we use this strictly for testing, till we have a better way to mock this provider
  test?: boolean;
}

export type IAuthProviderValues = {
  user: AuthUser;
  logout: () => void;
  isAdmin?: boolean;
  hasRoles: (roles: string[]) => boolean;
  hasPermission: (permission: string) => boolean;
  hasAnyPermission: (permissions: string[]) => boolean;
};

const defaultUser: AuthUser = {
  id: '',
  organizationId: 0,
  firstName: '',
  lastName: '',
  email: '',
  phone: '',
  permissions: [],
  roles: [],
};

export const AuthContext = createContext<IAuthProviderValues>({
  user: defaultUser,
  logout: () => {},
  hasRoles: () => false,
  hasPermission: () => false,
  hasAnyPermission: () => false,
});

export const useAuth = () => {
  if (!AuthContext) {
    throw Error('useAuth can only be used within an AuthContextProvider');
  }

  return useContext(AuthContext);
};

export const AuthProvider = ({ children, test }: IAuthProviderProps) => {
  const navigate = useNavigate();
  const { setUser: setPermifyUser } = usePermify();

  const [authenticated, setAuthenticated] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [user, setUser] = useState<AuthUser>(defaultUser);
  const [userPermissions, setUserPermissions] = useState<string[]>([]);

  const [getAuthenticatedUser] = useLazyAuthenticatedUserQuery();

  useEffect(() => {
    const getAuthUser = async () => {
      const token = localStorage.getItem(AUTH_TOKEN_KEY);
      setAuthorizationToken(token);

      try {
        const authUser = await getAuthenticatedUser();
        let decodedPermissions: string[] = [];

        if (token) {
          const decodedToken: DecodedToken = jwtDecode(token);
          decodedPermissions = decodedToken.permissions ?? [];
        }

        setUserPermissions(decodedPermissions);
        setUser({ ...authUser.data?.getAuthenticatedUser!, permissions: decodedPermissions });
        setAuthenticated(true);
        setPermifyUser({
          id: authUser.data?.getAuthenticatedUser.id.toString()!,
          roles: authUser.data?.getAuthenticatedUser.roles,
          permissions: decodedPermissions,
        });
      } catch (e: any) {
        if (e.response && e.response.status === 401) {
          setAuthenticated(false);
        }
      }

      setLoading(false);
    };

    if (test) {
      setUser(defaultUser);
      setAuthenticated(true);
    } else {
      getAuthUser();
    }
  }, []);

  const logout = useCallback(() => {
    setLoading(true);
    setAuthorizationToken(null);
    clearTerminalToken();
    clearSessionToken();
    setAuthenticated(false);

    const options: NavigateOptions = { replace: true };
    // by default lets navigate to the login page
    navigate('/', options);
  }, []);

  const hasRoles = useCallback(
    (roles: string[]) => {
      return user.roles?.some((role) => roles.includes(role)) ?? false;
    },
    [user.roles],
  );

  const hasPermission = useCallback(
    (permission: string): boolean => {
      return userPermissions.includes(permission);
    },
    [userPermissions],
  );

  const hasAnyPermission = useCallback(
    (permissions: string[]): boolean => {
      return permissions.some((permission) => hasPermission(permission));
    },
    [hasPermission],
  );

  const isAdmin = useMemo(() => {
    return user.roles?.includes(USER_ROLE.Admin);
  }, [user.roles]);

  const value = useMemo(
    () => ({ user, logout, isAdmin, hasRoles, hasPermission, hasAnyPermission }),
    [user, logout, isAdmin, hasRoles, hasPermission, hasAnyPermission],
  );

  if (loading) {
    return <LoadingView />;
  }

  if (!authenticated) {
    return (
      <Result
        status="403"
        title="Unauthorised Access"
        subTitle="Sorry, you are not authorized to access this page."
        extra={
          <Link replace to="/">
            Back Home
          </Link>
        }
      />
    );
  }

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};
