/* eslint-disable max-len */
import { Button, Table, Card, Tooltip, Switch } from 'antd';
import { EditOutlined, PlusOutlined } from '@ant-design/icons';

import { useEffect, useState } from 'react';
import { usePage } from 'src/components/page/Page';
import { TripConfigModal } from 'src/screens/trip-configuration/trip-config-modal/TripConfigModal';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';
import { tripConfigColumns } from 'src/screens/trip-configuration/TripConfigurationUtils';
import { useGetTripConfigs } from 'src/hooks/useGetTripConfigs';
import { ITripConfiguration } from 'src/types/trip.type';
import { PBACContainer } from 'src/components/RBAC/RBACContainer';
import { PERMISSIONS } from 'src/types/user.type';
import { useUpdateTripConfigStatus } from 'src/hooks/useUpdateTripConfigStatus';
import { useMessage } from 'src/providers/message-provider/MessageProvider';
import { PaginatedInterface } from 'src/types/type';
import { useAuth } from 'src/providers/auth-provider/AuthProvider';

export interface ITripQuery extends PaginatedInterface {
  branchId: number;
  limit?: number;
}

export const TripConfiguration = () => {
  const { setSubTitle, setTitle } = usePage();
  const { activeBranch } = useBranch();
  const { successMessage } = useMessage();
  const { user } = useAuth();

  const [showNewConfigModal, setShowNewConfigModal] = useState<boolean>(false);
  const [editableConfig, setEditableConfig] = useState<ITripConfiguration>();
  const [query, setQuery] = useState<ITripQuery>({
    branchId: activeBranch?.id!,
  });
  const [{ data, loading }, fetchConfigs] = useGetTripConfigs({ params: query });
  const [updateTripConfigStatus, { loading: updateTripConfigStatusLoading }] = useUpdateTripConfigStatus();

  const isLoading = loading || updateTripConfigStatusLoading;

  useEffect(() => {
    setTitle('Trip');
    setSubTitle('Trip Configuration');
  }, []);

  const onTripConfigCreated = async () => {
    await fetchConfigs({ params: query });
    setShowNewConfigModal(false);
  };

  const onEditConfigClicked = (config: ITripConfiguration) => {
    setEditableConfig(config);
    setShowNewConfigModal(true);
  };

  const onModalClose = () => {
    setShowNewConfigModal(false);
    setEditableConfig(undefined);
  };

  const onConfigStatusChange = async (configId: number, active: boolean) => {
    const updated = await updateTripConfigStatus({ params: { configId, active } });
    if (updated) {
      successMessage({ content: 'Config status updated' });
      await fetchConfigs({ params: { branchId: activeBranch?.id! } });
    }
  };

  const onPageChange = (currenctPage: number, newPageSize: number) => {
    setQuery({ ...query, page: currenctPage, offset: newPageSize });
  };

  const columns = [...tripConfigColumns];
  if (user?.permissions?.includes(PERMISSIONS.WriteTripConfig)) {
    columns.push({
      title: 'Enabled',
      key: 'active',
      dataIndex: 'active',
      width: 100,
      render: (active: boolean, { id }) => (
        <Switch defaultChecked={active} onChange={(checked: boolean) => onConfigStatusChange(id, checked)} />
      ),
    });

    columns.push({
      title: 'Action',
      key: 'action',
      fixed: 'right',
      width: 100,
      align: 'center',
      render: (config: ITripConfiguration) => (
        <Tooltip title="Edit config">
          <EditOutlined onClick={() => onEditConfigClicked(config)} />
        </Tooltip>
      ),
    });
  }

  return (
    <PBACContainer permissions={[PERMISSIONS.ReadTripConfig, PERMISSIONS.WriteTripConfig]}>
      <Card
        bordered={false}
        extra={[
          <Button key="add-config" type="primary" icon={<PlusOutlined />} disabled={!user?.permissions?.includes(PERMISSIONS.WriteTripConfig)} onClick={() => setShowNewConfigModal(true)}>
            Add New
          </Button>,
        ]}
      >
        <Table
          rowKey={(_ata: ITripConfiguration) => `${_ata.id}`}
          columns={columns}
          dataSource={data?.items}
          bordered
          loading={isLoading}
          pagination={{
            pageSize: data?.meta.offset,
            defaultCurrent: data?.meta.page,
            total: data?.meta.totalItems,
            onChange: onPageChange,
            showSizeChanger: true,
          }}
        />
      </Card>
      <TripConfigModal
        show={showNewConfigModal}
        onClose={onModalClose}
        onTripConfigChange={onTripConfigCreated}
        defaultValue={editableConfig}
      />
    </PBACContainer>
  );
};
