/* eslint-disable max-len */
import { Layout, Menu, Card, Typography, Avatar, Dropdown } from 'antd';
import {
  DashboardOutlined,
  HighlightOutlined,
  UserOutlined,
  CarOutlined,
  DragOutlined,
  DollarOutlined,
  CheckOutlined,
  CaretDownOutlined,
  AuditOutlined,
  BookOutlined,
  SolutionOutlined,
  DropboxOutlined,
  SendOutlined,
  AimOutlined,
  PercentageOutlined,
} from '@ant-design/icons';
import { ReactNode, useState } from 'react';
import { Outlet, Link } from 'react-router-dom';
import 'src/components/admin-layout/styles/adminLayout.styles.less';
import { styles } from 'src/components/admin-layout/styles/layoutStyles';
import { useAuth } from 'src/providers/auth-provider/AuthProvider';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';
import { clearTerminalToken } from 'src/utilities/storage.utils';
import { PERMISSIONS } from 'src/types/user.type';

const { Sider, Content } = Layout;
const { Item, SubMenu } = Menu;
const { Text } = Typography;

export interface AdminLayoutProps {
  children?: ReactNode;
}

export const AccountSwitcher = ({ isCollapsed }: { isCollapsed: boolean }) => {
  const { user } = useAuth();
  const { activeBranch, branches, setActiveBranchId } = useBranch();

  const onBranchSwitch = (item: any) => {
    clearTerminalToken();
    setActiveBranchId(parseInt(item.key, 10));
  };

  const menu = (
    <Menu
      selectable
      selectedKeys={[`${activeBranch?.id}`]}
      onSelect={onBranchSwitch}
      items={
        branches?.map((branch) => ({
          key: branch.id,
          label: branch.name,
          icon: activeBranch?.id === branch.id && <CheckOutlined />,
        })) ?? []
      }
    />
  );

  return (
    <div className="card-div">
      <Dropdown overlay={menu} trigger={['click']}>
        {isCollapsed ? (
          <Avatar size="large" style={styles.avatar}>
            {`${user?.firstName.charAt(0)}.${user?.lastName?.charAt(0)}`}
          </Avatar>
        ) : (
          <Card size="small" className="user-card">
            <div className="account-info-display">
              <Avatar size="large" style={styles.avatar}>
                {`${user?.firstName.charAt(0)}.${user?.lastName?.charAt(0)}`}
              </Avatar>
              <div className="name-display">
                <Text strong>OIS</Text>
                <Text>{`${user?.firstName} ${user?.lastName}`}</Text>
                <Text type="secondary">{activeBranch?.name}</Text>
              </div>
              <CaretDownOutlined />
            </div>
          </Card>
        )}
      </Dropdown>
    </div>
  );
};

export const AdminLayout = ({ children }: AdminLayoutProps) => {
  const [isCollapsed, setIsCollapsed] = useState<boolean>(true);
  const { hasAnyPermission } = useAuth();
  return (
    <Layout className="main-container">
      <Sider
        collapsible
        collapsed={isCollapsed}
        onCollapse={(value) => setIsCollapsed(value)}
        className="sider-box"
        width={267}
      >
        <AccountSwitcher isCollapsed={isCollapsed} />
        <Menu mode="inline" className="sider-menu">
          <Item key="dashboard" icon={<DashboardOutlined />}>
            <Link className="sidebar-links" to="/dashboard">
              Dashboard
            </Link>
          </Item>
          {hasAnyPermission([PERMISSIONS.WriteReservation, PERMISSIONS.WriteWaybill]) && (
            <Item className="sidebar-link-item" icon={<AuditOutlined />}>
              <Link className="sidebar-links" to="/ticketing">
                E-Ticketing
              </Link>
            </Item>
          )}

          {hasAnyPermission([PERMISSIONS.WriteReservation, PERMISSIONS.WriteWaybill, PERMISSIONS.ViewReservation]) && (
            <Item className="sidebar-link-item" icon={<BookOutlined />}>
              <Link className="sidebar-links" to="/reservations">
                Reservations
              </Link>
            </Item>
          )}
          {hasAnyPermission([
            PERMISSIONS.ReadPassenger,
            PERMISSIONS.WritePassenger,
            PERMISSIONS.DeleteTicket,
            PERMISSIONS.DeletePassenger,
            PERMISSIONS.CancelTicket,
          ]) && (
            <Item className="sidebar-link-item" icon={<SolutionOutlined />}>
              <Link className="sidebar-links" to="/passengers">
                Passengers
              </Link>
            </Item>
          )}

          {hasAnyPermission([
            PERMISSIONS.ReadWaybill,
            PERMISSIONS.WriteWaybill,
            PERMISSIONS.DeleteWaybill,
            PERMISSIONS.TransferWaybill,
            PERMISSIONS.CancelWaybill,
          ]) && (
            <Item className="sidebar-link-item" icon={<DropboxOutlined />}>
              <Link className="sidebar-links" to="/waybills">
                Waybills
              </Link>
            </Item>
          )}

          {hasAnyPermission([PERMISSIONS.ReadUser, PERMISSIONS.WriteUser, PERMISSIONS.DeleteUser]) && (
            <Item key="users" icon={<UserOutlined />} className="sidebar-link-item">
              <Link className="sidebar-links" to="/users">
                Users
              </Link>
            </Item>
          )}

          {hasAnyPermission([PERMISSIONS.ReadDiscount, PERMISSIONS.WriteDiscount, PERMISSIONS.DeleteDiscount]) && (
            <Item key="discounts" icon={<PercentageOutlined />} className="sidebar-link-item">
              <Link className="sidebar-links" to="/discounts">
                Discounts
              </Link>
            </Item>
          )}

          {hasAnyPermission([
            PERMISSIONS.ReadDestination,
            PERMISSIONS.WriteDestination,
            PERMISSIONS.DeleteDestination,
          ]) && (
            <Item key="destinations" icon={<AimOutlined />} className="sidebar-link-item">
              <Link className="sidebar-links" to="/destinations">
                Destinations
              </Link>
            </Item>
          )}
          {hasAnyPermission([PERMISSIONS.ReadTrip, PERMISSIONS.WriteTrip, PERMISSIONS.DeleteTrip]) && (
            <SubMenu key="trips" icon={<SendOutlined />} title="Trip management">
              <Item key="trips-index" className="sidebar-link-item">
                <Link className="sidebar-links" to="/trips">
                  View Trips
                </Link>
              </Item>
              {hasAnyPermission([
                PERMISSIONS.ReadTripConfig,
                PERMISSIONS.WriteTripConfig,
                PERMISSIONS.DeleteTripConfig,
              ]) && (
                <Item key="trip-configuration" className="sidebar-link-item">
                  <Link className="sidebar-links" to="/trips/configuration">
                    Trip Configuration
                  </Link>
                </Item>
              )}
            </SubMenu>
          )}

          {hasAnyPermission([
            PERMISSIONS.ReadTransactions,
            PERMISSIONS.WriteTransactions,
            PERMISSIONS.DeleteTransactions,
          ]) && (
            <SubMenu key="finance" icon={<DollarOutlined />} title="Finances">
              <Item key="transactions" className="sidebar-link-item">
                <Link className="sidebar-links" to="/transactions">
                  Transactions
                </Link>
              </Item>
              {hasAnyPermission([PERMISSIONS.ReadExpense, PERMISSIONS.WriteExpense, PERMISSIONS.DeleteExpense]) && (
                <Item key="expenses" className="sidebar-link-item">
                  <Link className="sidebar-links" to="/expenses">
                    Expenses
                  </Link>
                </Item>
              )}
            </SubMenu>
          )}

          {hasAnyPermission([PERMISSIONS.ReadVehicle, PERMISSIONS.WriteVehicle, PERMISSIONS.DeleteVehicle]) && (
            <SubMenu key="vehicles" icon={<CarOutlined />} title="Vehicle Management">
              <Item className="sidebar-link-item">
                <Link className="sidebar-links" to="/vehicles">
                  Vehicles
                </Link>
              </Item>
              {hasAnyPermission([
                PERMISSIONS.ReadVehicleClass,
                PERMISSIONS.WriteVehicleClass,
                PERMISSIONS.DeleteVehicleClass,
              ]) && (
                <Item className="sidebar-link-item">
                  <Link className="sidebar-links" to="/vehicles/class">
                    Vehicle Class
                  </Link>
                </Item>
              )}
              <Item className="sidebar-link-item">
                <Link className="sidebar-links" to="/vehicles/class-configuration">
                  Class Configuration
                </Link>
              </Item>
            </SubMenu>
          )}

          {hasAnyPermission([PERMISSIONS.ReadRoute, PERMISSIONS.WriteRoute, PERMISSIONS.DeleteRoute]) && (
            <SubMenu key="routes" icon={<DragOutlined />} title="Routes">
              <Item key="routes-index" className="sidebar-link-item">
                <Link className="sidebar-links" to="/routes">
                  Manage Routes
                </Link>
              </Item>
              {hasAnyPermission([PERMISSIONS.ReadRouteFare, PERMISSIONS.WriteRouteFare]) && (
                <Item key="routes-fare">
                  <Link className="sidebar-links" to="/routes/fare">
                    Route Fares
                  </Link>
                </Item>
              )}

              {hasAnyPermission([PERMISSIONS.ReadRouteFare, PERMISSIONS.WriteRouteFare]) && (
                <Item key="fare-overrides">
                  <Link className="sidebar-links" to="/routes/fare-overrides">
                    Fare Overrides
                  </Link>
                </Item>
              )}

              {hasAnyPermission([
                PERMISSIONS.ReadRouteGroup,
                PERMISSIONS.WriteRouteGroup,
                PERMISSIONS.DeleteRouteGroup,
              ]) && (
                <Item key="routes-group">
                  <Link className="sidebar-links" to="/routes/groups">
                    Route Group
                  </Link>
                </Item>
              )}
            </SubMenu>
          )}

          {hasAnyPermission([PERMISSIONS.ReadTripSchedule, PERMISSIONS.WriteTripSchedule]) && (
            <SubMenu key="travel" icon={<HighlightOutlined />} title="Travel">
              <Item key="travel-schedule" className="sidebar-link-item">
                <Link className="sidebar-links" to="/travel/schedule">
                  Travel Schedule
                </Link>
              </Item>
            </SubMenu>
          )}
        </Menu>
      </Sider>
      <Layout>
        <Content className="content-box">
          <div className="outlet">
            {children ?? null}
            <Outlet />
          </div>
        </Content>
      </Layout>
    </Layout>
  );
};
