/* eslint-disable max-len */
import { Button, Table, Card } from 'antd';
import { PlusOutlined, EditOutlined } from '@ant-design/icons';
import { useEffect, useState } from 'react';

import 'src/screens/vehicle-class/styles/busClassManagement.styles.less';
import { usePage } from 'src/components/page/Page';
import { VehicleClassModal } from 'src/screens/vehicle-class/vehicle-class-modal/VehicleClassModal';
import { IVehicleClass } from 'src/types/vehicle.type';
import { useGetVehicleClasses } from 'src/hooks/useGetVehicleClasses';
import { useMessage } from 'src/providers/message-provider/MessageProvider';
import { vehicleClassColumns } from 'src/screens/vehicle-class/VehicleClassUtils';
import { PBACContainer } from 'src/components/RBAC/RBACContainer';
import { useAuth } from 'src/providers/auth-provider/AuthProvider';
import { PERMISSIONS } from 'src/types/user.type';

export const VehicleClass = () => {
  const { setSubTitle, setTitle } = usePage();
  const [{ data, loading }, fetchVehicleClasses] = useGetVehicleClasses();
  const { successMessage } = useMessage();
  const { user } = useAuth();
  const [showVehicleClassModal, setShowVehicleClassModal] = useState<boolean>(false);
  const [selectedVehicleClass, setSelectedVehicleClass] = useState<IVehicleClass>();

  useEffect(() => {
    setTitle('Vehicle Management');
    setSubTitle('Vehicle Class');
  }, []);

  const closeVehicleClassModal = () => {
    setSelectedVehicleClass(undefined);
    setShowVehicleClassModal(false);
  };
  const showEditVehicleClassModal = (id: number) => {
    const vehicleClass = data?.find((vc) => vc.id === id);
    setSelectedVehicleClass(vehicleClass);
    setShowVehicleClassModal(true);
  };

  const onVehicleClassActionComplete = async () => {
    await fetchVehicleClasses();
    closeVehicleClassModal();
    successMessage({ content: 'Request successfully completed', duration: 2.5 });
  };

  const columns = [...vehicleClassColumns];
  if (user?.permissions?.includes(PERMISSIONS.WriteVehicleClass)) {
    columns.push({
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      render: (id: number) => (
        <EditOutlined onClick={() => showEditVehicleClassModal(id)} className="more-btn" href="#" />
      ),
      fixed: 'right',
      align: 'center',
      width: 100,
    });
  }

  return (
    <div className="buses-page-container">
      <PBACContainer permissions={[PERMISSIONS.ReadVehicleClass, PERMISSIONS.WriteVehicleClass]}>
        <Card
          bordered={false}
          extra={[
            <Button
              key="add-bus-class"
              type="primary"
              icon={<PlusOutlined />}
              disabled={!user?.permissions?.includes(PERMISSIONS.WriteVehicle)}
              onClick={() => setShowVehicleClassModal(true)}
            >
              Add New
            </Button>,
          ]}
        >
          <Table
            rowKey={(_ata: any) => _ata.id}
            columns={columns}
            dataSource={data}
            bordered
            loading={loading}
            pagination={{ pageSize: 8, total: data?.length }}
            scroll={{ x: 500 }}
          />
        </Card>
        <VehicleClassModal
          show={showVehicleClassModal}
          onClose={closeVehicleClassModal}
          onActionComplete={onVehicleClassActionComplete}
          defaultVehicleClass={selectedVehicleClass}
        />
      </PBACContainer>
    </div>
  );
};
