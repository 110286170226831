import { LazyQueryResultTuple, gql } from '@apollo/client';
import { useLazyQueryErrorNotification } from 'src/graphql/hooks/useLazyQueryErrorNotification';
import {
  GetAuthenticatedUserQuery,
  GetAuthenticatedUserQueryVariables,
} from 'src/graphql/queries/__generated__/get-authenticated-user.generated';

export type AuthUser = GetAuthenticatedUserQuery['getAuthenticatedUser'];

const GET_AUTHENTICATED_USER_QUERY = gql`
  query GetAuthenticatedUser {
    getAuthenticatedUser {
      email
      firstName
      id
      lastName
      permissions
      phone
      roles
      organizationId
    }
  }
`;

export const useLazyAuthenticatedUserQuery = (): LazyQueryResultTuple<
  GetAuthenticatedUserQuery,
  GetAuthenticatedUserQueryVariables
> =>
  useLazyQueryErrorNotification<GetAuthenticatedUserQuery, GetAuthenticatedUserQueryVariables>(
    GET_AUTHENTICATED_USER_QUERY,
    { fetchPolicy: 'network-only' },
  );
