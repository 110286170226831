import { QueryResult, gql } from '@apollo/client';
import { useQueryErrorNotification } from 'src/graphql/hooks/useQueryErrorNotification';
import {
  GetReservationByIdQuery,
  GetReservationByIdQueryVariables,
} from 'src/graphql/queries/__generated__/get-reservation-by-id.generated';

export type ReservationTrip = GetReservationByIdQuery['getReservationById']['trip'];
export type ReservationSchedule = GetReservationByIdQuery['getReservationById']['schedule'];
export type ReservationTransaction = GetReservationByIdQuery['getReservationById']['transaction'];
export type ReservationTicket = GetReservationByIdQuery['getReservationById']['tickets'][number];

const GET_RESERVATION_BY_ID_QUERY = gql`
  query GetReservationById($reservationId: Float!) {
    getReservationById(reservationId: $reservationId) {
      trip {
        routeGroup {
          name
        }
        date
        time
        id
        isCheckinAvailable
        expectedCheckinTime
      }
      tripId
      transaction {
        serviceCharge
        amount
        paymentMethod
        processedAt
        status
        createdAt
        ticketer {
          firstName
          lastName
        }
      }
      tickets {
        passenger {
          fullName
          phone
          email
          seatId
          seat {
            id
            seatMapId
            number
          }
          isPrimaryPassenger
          checkInTime
          gender
          nextKinFirstName
          type
          nextKinLastName
          nextKinPhone
          id
        }
        id
      }
      schedule {
        route {
          destination {
            name
          }
          branch {
            name
          }
        }
      }
    }
  }
`;

export const useGetReservationById = (
  reservationId: number,
): QueryResult<GetReservationByIdQuery, GetReservationByIdQueryVariables> =>
  useQueryErrorNotification<GetReservationByIdQuery, GetReservationByIdQueryVariables>(GET_RESERVATION_BY_ID_QUERY, {
    variables: { reservationId },
  });
