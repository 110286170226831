/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import { Card, Button } from 'antd';
import { Link } from 'react-router-dom';
import moment from 'moment';
import Table, { ColumnsType } from 'antd/lib/table';

import 'src/screens/travel-schedule/styles/travelSchedule.style.less';
import { usePage } from 'src/components/page/Page';
import { ITravelSchedule } from 'src/types/travel.type';
import { MoreOutlined, PlusOutlined } from '@ant-design/icons';
import { CreateTravelSchedule } from 'src/screens/travel-schedule/new-shedule-modal/AddScheduleModal';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';
import { ITrip } from 'src/types/trip.type';
import { MoneyText } from 'src/components/topography/MoneyText';
import { IRoute } from 'src/types/route.type';
import { ScheduleFilter } from 'src/screens/travel-schedule/components/ScheduleFilter';
import { UseGetTravelScheduleParams, useGetTravelSchedules } from 'src/hooks/useGetTravelSchedule';
import { DEFAULT_DATE_FORMAT } from 'src/utilities/date.utils';
import { getTripDateTime } from 'src/screens/trips/TripUtils';
import { useAuth } from 'src/providers/auth-provider/AuthProvider';
import { PERMISSIONS } from 'src/types/user.type';
import { PBACContainer } from 'src/components/RBAC/RBACContainer';

export const TRAVEL_SCEDULE_TEST_ID = 'TRAVEL_SCEDULE_TEST_ID';

export const TravelSchedule: React.FC = () => {
  const { setSubTitle, setTitle } = usePage();
  const { user } = useAuth();
  const { activeBranch } = useBranch();

  const [filterQuery, setFilterQuery] = useState<UseGetTravelScheduleParams>({
    branchId: activeBranch?.id!,
    startDate: moment().format(DEFAULT_DATE_FORMAT),
    endDate: moment().format(DEFAULT_DATE_FORMAT),
    page: 1,
    offset: 100,
  });
  const [{ data, loading }, fetchSchedules] = useGetTravelSchedules({ params: filterQuery });
  const [showModal, setShowModal] = useState<boolean>(false);
  const [scheduleEdit, setScheduleEdit] = useState<ITravelSchedule>();

  useEffect(() => {
    setTitle('Travel');
    setSubTitle('Travel Schedule');
  }, []);

  const onScheduleEdit = async (schedule: ITravelSchedule) => {
    setScheduleEdit(schedule);
    setShowModal(true);
  };

  const onCloseModal = () => {
    setScheduleEdit(undefined);
    setShowModal(false);
  };

  const onScheduleActionCompleted = async () => {
    await fetchSchedules({ params: filterQuery });
    onCloseModal();
  };

  const onPageChange = (currenctPage: number, newPageSize: number) => {
    setFilterQuery({ ...filterQuery, page: currenctPage, offset: newPageSize });
  };

  const columns: ColumnsType<ITravelSchedule> = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Route',
      dataIndex: 'route',
      key: 'route',
      render: (route: IRoute) => (
        <span>{`${route?.branch!.name} → ${route?.destination!.name}(${route?.destination?.code})`}</span>
      ),
    },
    {
      title: 'Date',
      dataIndex: 'trip',
      key: 'trip',
      render: (trip: ITrip) => moment(trip?.date).format('ddd, Do MMM YYYY'),
    },
    {
      title: 'Time',
      dataIndex: 'trip',
      key: 'trip',
      render: (trip: ITrip) => getTripDateTime(trip).time,
    },
    {
      title: 'Class',
      dataIndex: 'trip',
      key: 'trip',
      render: (trip?: ITrip) => trip?.vehicleClass?.name,
    },
    {
      title: 'Trip',
      dataIndex: 'trip',
      key: 'trip',
      render: (trip: ITrip) => {
        const hasPermission = user?.permissions?.includes(PERMISSIONS.ReadTrip) || user?.permissions?.includes(PERMISSIONS.WriteTrip);

        if (!hasPermission) {
          return <span>{`${trip.destination?.name} | ${getTripDateTime(trip).dateTime}`}</span>;
        }

        const { dateTime } = getTripDateTime(trip);
        return <Link to={`/trips/${trip.id}`}>{`${trip.destination?.name} | ${dateTime}`}</Link>;
      },
    },
    {
      title: 'Fare',
      dataIndex: 'travelFare',
      key: 'travelFare',
      render: (travelFare: number, s: ITravelSchedule) => <MoneyText value={travelFare + s.serviceCharge} />,
    },
    {
      title: 'Action',
      fixed: 'right',
      width: 100,
      render: (schedule: ITravelSchedule) => {
        return (
          <Button
            onClick={() => onScheduleEdit(schedule)}
            icon={<MoreOutlined />}
            disabled={!user?.permissions?.includes(PERMISSIONS.WriteTripSchedule)}
          />
        );
      },
    },
  ];

  return (
    <PBACContainer permissions={[PERMISSIONS.ReadTripSchedule, PERMISSIONS.WriteTripSchedule]}>
      <>
        <ScheduleFilter onApply={setFilterQuery} />
        <Card
          key="TRAVEL_SCHEDULE_KEY"
          data-testid={TRAVEL_SCEDULE_TEST_ID}
          bordered={false}
          extra={[
            <Button
              key="add-schedule-key"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => setShowModal(true)}
              disabled={!user?.permissions?.includes(PERMISSIONS.WriteTripSchedule)}
            >
              Add New
            </Button>,
          ]}
        >
          {data && (
            <Table
              loading={loading}
              rowKey={(_ata: any) => _ata.id}
              columns={columns}
              dataSource={data.items}
              pagination={{
                pageSize: data.meta?.offset,
                current: data.meta?.page,
                onChange: onPageChange,
                showSizeChanger: true,
                total: data.meta?.totalItems,
              }}
              scroll={{ x: 1000 }}
            />
          )}
        </Card>
        {showModal && (
          <CreateTravelSchedule
            show={showModal}
            onClose={onCloseModal}
            onComplete={onScheduleActionCompleted}
            initialSchedule={scheduleEdit}
          />
        )}
      </>
    </PBACContainer>
  );
};
