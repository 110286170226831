import React, { useEffect } from 'react';
import moment from 'moment';

import { Button, Typography } from 'antd';
import { Verified } from 'src/icons/Verified.icon';
import { Cash } from 'src/icons/Cash.icon';
import { Pos } from 'src/icons/Pos.icon';
import { Transfer } from 'src/icons/Transfer.icon';
import { useReservation } from 'src/providers/reservation-provider/ReservationContext';
import { PAYMENT_METHODS } from 'src/types/transaction.type';

import 'src/screens/success-page/styles/successPage.styles.less';
import { usePage } from 'src/components/page/Page';
import { MoneyText } from 'src/components/topography/MoneyText';
import { TicketList } from 'src/components/ticket-list/TicketList';
import { PrinterOutlined } from '@ant-design/icons';

export const PAGE_TEST_ID = 'PAGE_TEST_ID';

export const switchPaymentIcon = (method: PAYMENT_METHODS) => {
  switch (method) {
    case PAYMENT_METHODS.CASH:
      return <Cash />;
    case PAYMENT_METHODS.BANK_TRANSFER:
      return <Transfer />;
    case PAYMENT_METHODS.POS:
      return <Pos />;
    default:
      return null;
  }
};

export const switchPaymentMethodText = (method: PAYMENT_METHODS) => {
  switch (method) {
    case PAYMENT_METHODS.CASH:
      return 'Cash';
    case PAYMENT_METHODS.BANK_TRANSFER:
      return 'Online Bank Transfer';
    case PAYMENT_METHODS.POS:
      return 'POS Terminal';
    default:
      return null;
  }
};

export const PaymentSuccessPage: React.FC = () => {
  const { transaction, reservationType, waybill, tickets, passengers } = useReservation();
  const { setSubTitle, setTitle } = usePage();

  useEffect(() => {
    setTitle('E-ticketing');
    setSubTitle('Confirmation');
  }, []);

  const onDoneClicked = () => {
    // we want to force the redirect to reload
    window.location.href = '/ticketing';
  };

  const total = (transaction?.amount ?? 0) + (transaction?.serviceCharge ?? 0);
  return (
    <div className="success-container" data-testid={PAGE_TEST_ID}>
      <Verified />
      <h1 className="mt-10">
        <b>Reservation successfully completed</b>
      </h1>
      {reservationType === 'Waybill' && <p>Reference: {waybill?.reference}</p>}
      <div className="payment-type">
        <h1>
          <b>{switchPaymentIcon(transaction?.paymentMethod!.toUpperCase() as PAYMENT_METHODS)}</b>
        </h1>
        <div className="details">
          <p className="title">
            {switchPaymentMethodText(transaction?.paymentMethod!.toUpperCase() as PAYMENT_METHODS)}
          </p>
          <p>
            <MoneyText value={total} /> paid on {moment(transaction?.processedAt).format('ddd Do MMM, YYYY hh:mm A')}
          </p>
        </div>
      </div>
      {reservationType === 'Passenger' && (
        <div className="fw mt-30 mb-30">
          <Typography.Title level={5}>Tickets</Typography.Title>
          <TicketList tickets={tickets} passengers={passengers} />
        </div>
      )}
      {reservationType === 'Waybill' && (
        <Button
          href={`/waybill/${waybill?.id}/print`}
          target="blank"
          className="button"
          size="large"
          type="primary"
          icon={<PrinterOutlined />}
        >
          PRINT WAYBILL
        </Button>
      )}
      <Button className="button" size="large" type="ghost" onClick={onDoneClicked}>
        DONE
      </Button>
    </div>
  );
};
