import { PrinterOutlined } from '@ant-design/icons';
import { Button, Descriptions, Popconfirm, Space, message } from 'antd';
import moment from 'moment';
import { useState } from 'react';
import { useCloseTripMutation } from 'src/graphql/mutations/close-trip';
import { useOpenTripMutation } from 'src/graphql/mutations/open-trip';
import { useAuth } from 'src/providers/auth-provider/AuthProvider';

import { ITrip } from 'src/types/trip.type';
import { TripModal } from 'src/screens/trips/trip-modal/TripModal';
import { PBACContainer } from 'src/components/RBAC/RBACContainer';
import { PERMISSIONS } from 'src/types/user.type';

export interface ITripInfromationProps {
  trip: ITrip;
  onCloseTrip?: () => void;
  onOpenTrip?: () => void;
}

export const TripInformation = ({ trip, onCloseTrip, onOpenTrip }: ITripInfromationProps) => {
  const [openConfirm, setOpenConfirm] = useState<boolean>(false);
  const { user: authUser } = useAuth();
  const [openTrip, { loading: loadingOpenTrip }] = useOpenTripMutation();
  const [closeTrip, { loading: loadingCloseTrip }] = useCloseTripMutation();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [selectedTrip, setSelectedTrip] = useState<ITrip>();

  const dateTime = `${trip.date} ${trip.time}`;

  const onConfirmCloseTrip = async () => {
    const closedTrip = await closeTrip({
      variables: {
        tripId: trip.id,
      },
    });
    if (closedTrip.data && onCloseTrip) {
      onCloseTrip();
    }
  };

  const onConfirmOpenTrip = async () => {
    const openedTrip = await openTrip({
      variables: {
        tripId: trip.id,
      },
    });
    if (openedTrip && onOpenTrip) {
      onOpenTrip();
    }
  };

  const onEditTrip = () => {
    setSelectedTrip(trip);
    setShowModal(true);
    setIsEdit(true);
  };

  const onTripActionCompleted = async () => {
    message.success('Trip successfully updated');
    setShowModal(false);
  };

  return (
    <div style={{ marginBottom: 70 }}>
      <Descriptions title="Trip Details" column={1} labelStyle={{ marginRight: 20 }}>
        <Descriptions.Item label="Departure Date">{moment(dateTime).format('DD-MM-YYYY')}</Descriptions.Item>
        <Descriptions.Item label="Depature Time">{moment(dateTime).format('hh:mm A')}</Descriptions.Item>
        <Descriptions.Item label="Departure Terminal">{trip.branch?.name}</Descriptions.Item>
        <Descriptions.Item label="Final Destination">{trip.destination?.name}</Descriptions.Item>
        <Descriptions.Item label="Route Group">{trip.routeGroup?.name}</Descriptions.Item>
      </Descriptions>

      <Space className="mt-50">
        <Button type="primary" href={`/trip/${trip.id}/manifest`} target="_" icon={<PrinterOutlined />}>
          Print Manifest
        </Button>

        {!trip.closedAt && (
          <Popconfirm
            title="Are you sure you want to close this trip?"
            open={openConfirm}
            onConfirm={onConfirmCloseTrip}
            okButtonProps={{ loading: loadingCloseTrip }}
            onCancel={() => setOpenConfirm(false)}
          >
            <PBACContainer permissions={[PERMISSIONS.CloseTrip]}>
              <Button type="dashed" onClick={() => setOpenConfirm(true)}>
                Close Trip
              </Button>
            </PBACContainer>
          </Popconfirm>
        )}

        {trip.closedAt && authUser.permissions?.includes(PERMISSIONS.OpenTrip) && (
          <Popconfirm
            title="Are you sure you want to open this trip?"
            open={openConfirm}
            onConfirm={onConfirmOpenTrip}
            okButtonProps={{ loading: loadingOpenTrip }}
            onCancel={() => setOpenConfirm(false)}
          >
            <Button type="dashed" onClick={() => setOpenConfirm(true)}>
              Open Trip
            </Button>
          </Popconfirm>
        )}

        <Button href={`/trips/${trip.id}/printclosingpaper`} target="_blank" type="primary" icon={<PrinterOutlined />}>
          Print Closing Doc
        </Button>

        {!trip.closedAt && authUser.permissions?.includes(PERMISSIONS.WriteTrip) && (
          <Button type="primary" onClick={onEditTrip}>
            Edit Trip
          </Button>
        )}
      </Space>

      {showModal && (
        <TripModal
          show={showModal}
          onTripActionCompleted={onTripActionCompleted}
          onClose={() => setShowModal(false)}
          initialTrip={selectedTrip}
          isEdit={isEdit}
        />
      )}
    </div>
  );
};
