import { FC } from 'react';
import { useParams } from 'react-router-dom';
import { PassengerTicket } from 'src/components/passenger-ticket/PassengerTicket';
import { Printable } from 'src/components/print/Print';
import { useGetTicket } from 'src/hooks/useGetTicket';
import { PBACContainer } from 'src/components/RBAC/RBACContainer';
import { PERMISSIONS } from 'src/types/user.type';

export const PrintTicket: FC = () => {
  const params = useParams();
  const [{ data: ticket, loading }] = useGetTicket(parseInt(params.id!, 10));

  return (
    <PBACContainer permissions={[PERMISSIONS.ReadTicket, PERMISSIONS.WriteTicket]}>
      <Printable loading={loading}>
        {ticket && <PassengerTicket ticket={ticket} />}
      </Printable>
    </PBACContainer>
  );
};
