import { AUTH_TOKEN_KEY, DEFAULT_BRANCH_KEY, PERMIFY_USER_KEY, TERMINAL_KEY } from 'src/constants';

export const setTerminalToken = (value: string): void => {
  localStorage.setItem(TERMINAL_KEY, value);
};

export const clearTerminalToken = (): void => {
  localStorage.removeItem(TERMINAL_KEY);
};

export const clearSessionToken = (): void => {
  localStorage.removeItem(AUTH_TOKEN_KEY);
  localStorage.removeItem(PERMIFY_USER_KEY);
};

export const getSessionToken = (): string | null => {
  return localStorage.getItem(AUTH_TOKEN_KEY);
};

export const getTerminalToken = (): string | null => {
  return localStorage.getItem(TERMINAL_KEY);
};

export const setDefaultBranchId = (value: string) => {
  localStorage.setItem(DEFAULT_BRANCH_KEY, value);
};
