import React, { useState, useEffect } from 'react';
import { Form, Select, DatePicker, Button, Typography, Row, Col, message, Tabs } from 'antd';
import { SearchOutlined } from '@ant-design/icons';

import 'src/screens/search-trip/styles/searchTrip.style.less';
import { usePage } from 'src/components/page/Page';
import { IRoute } from 'src/types/route.type';
import { RoutesService } from 'src/services/routes.service';
import { TravelService } from 'src/services/travel.service';
import { ITravelScheduleSearchParams } from 'src/types/travel.type';
import { PASSENGERT_TYPE } from 'src/types/passanger.type';
import { useReservation } from 'src/providers/reservation-provider/ReservationContext';
import { SearchResultView } from 'src/components/search-result-view/SearchResultView';
import { useBranch } from 'src/providers/branch-provider/BranchProvider';
import { WaybillForm } from 'src/screens/waybill/waybill-form/WaybillForm';
import { onTripSearchFilter } from 'src/utilities/helpers.utils';
import { useNavigate, useParams } from 'react-router-dom';
import { useAuth } from 'src/providers/auth-provider/AuthProvider';
import { PERMISSIONS } from 'src/types/user.type';

const { Option } = Select;

export const FORM_TEST_ID = 'FORM_TEST_ID';

enum TAB_KEYS {
  PassengerTab = 'Passenger-tab',
  WaybillTab = 'Waybill-tab',
}

export const SearchTrip: React.FC = () => {
  const { activeBranch } = useBranch();
  const { setSearchParams, setSearchResults, setRoute, searchParams, setReservationType } = useReservation();
  const { setSubTitle, setTitle } = usePage();
  const [form] = Form.useForm();
  const params = useParams();
  const navigate = useNavigate();
  const { user } = useAuth();

  const [loadingRoutes, setLoadingRoutes] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>();
  const [routes, setRoutes] = useState<IRoute[]>([]);
  const [tabTitle, setTabTitle] = useState<string>('');
  const [activeTab, setActiveTab] = useState<TAB_KEYS>();

  useEffect(() => {
    setTitle('E-ticketing');

    const isWayBill = params['*'] === 'waybill';
    setTabTitle(!isWayBill ? 'Search for available trips' : 'Add Waybill');
    setReservationType(!isWayBill ? 'Passenger' : 'Waybill');
    setSubTitle(!isWayBill ? 'Search Trip' : 'Add Waybill');
    setActiveTab(!isWayBill ? TAB_KEYS.PassengerTab : TAB_KEYS.WaybillTab);
  }, [params, setTabTitle, setReservationType, setSubTitle, setActiveTab]);

  useEffect(() => {
    const getRoutes = async () => {
      setLoadingRoutes(true);
      const data = await RoutesService.getRoutes({ branchId: activeBranch?.id! });
      setRoutes(data);
      setLoadingRoutes(false);
    };

    getRoutes();
  }, [activeBranch, setLoadingRoutes, setRoutes]);

  const routeItems = routes.map((route: IRoute) => (
    <Option key={route.id} value={route.id}>
      {route.destination?.name.toUpperCase()}
    </Option>
  ));

  const handleSearch = async () => {
    const values = await form.validateFields();
    const selectedRoute = routes.find((route) => route.id === values.routeId);

    if (values) {
      const payload: ITravelScheduleSearchParams = {
        branchId: activeBranch?.id!,
        date: values.date.format('YYYY-MM-DD'),
        routeId: values.routeId,
        adult: values.adult,
        child: values.child,
        infant: values.infant,
        noTransform: true,
      };

      setLoading(true);
      try {
        const schedules = await TravelService.findAvailableSchedules(payload);

        setSearchResults(schedules);
        setSearchParams(payload);
        // (Amadosi 2022-09-30) at this point we know the form has been validated and a route selected.
        // unlikely selectedRoute will be null
        setRoute(selectedRoute!);

        message.success(`${schedules.length} schedules found`, 2);
      } catch (e) {
        message.error('Unable to get available trips, please try again', 5);
      }

      setLoading(false);
    }
  };

  const english = (count: number, key: PASSENGERT_TYPE) => {
    if (key === PASSENGERT_TYPE.ADULT) {
      return count > 1 ? 'Adults' : 'Adult';
    }
    if (key === PASSENGERT_TYPE.CHILD) {
      return count > 1 ? 'Children' : 'Child';
    }
    if (key === PASSENGERT_TYPE.INFANT) {
      return count > 1 ? 'Infants' : 'Infant';
    }

    return null;
  };

  const createoptions = (name: PASSENGERT_TYPE, number: number) => {
    const options = [];
    for (let i = 0; i < number; i += 1) {
      options.push(<Option key={i} value={i}>{`${i} ${english(i, name)}`}</Option>);
    }
    return options;
  };

  const setTabActions = (key: string) => {
    if (key === TAB_KEYS.WaybillTab) {
      navigate('/ticketing/waybill');
    } else {
      navigate('/ticketing');
    }
  };

  const onRouteSearch = (input: string, option?: { value: number }) => {
    return onTripSearchFilter(routes, input, option);
  };

  const hasPermission = user?.permissions?.includes(PERMISSIONS.WriteReservation);

  return (
    <div className="search-trip-container">
      <div className="header-title">
        <Typography.Title level={4}>{tabTitle}</Typography.Title>
      </div>
      <Tabs activeKey={activeTab} size="middle" type="card" centered onChange={setTabActions}>
        <Tabs.TabPane tab="Passengers" key={TAB_KEYS.PassengerTab}>
          <div className="form-container-card">
            <Form
              className="form"
              size="large"
              layout="vertical"
              autoComplete="on"
              initialValues={{
                [PASSENGERT_TYPE.ADULT.toLowerCase()]: 1,
                [PASSENGERT_TYPE.CHILD.toLowerCase()]: 0,
                [PASSENGERT_TYPE.INFANT.toLowerCase()]: 0,
              }}
              data-testid={FORM_TEST_ID}
              form={form}
            >
              <Form.Item
                className="input"
                label="Destination"
                name="routeId"
                rules={[{ required: true, message: 'Please select a destination' }]}
              >
                <Select
                  showSearch
                  placeholder="Select Destination"
                  loading={loadingRoutes}
                  filterOption={onRouteSearch}
                  disabled={!hasPermission}
                >
                  {routeItems}
                </Select>
              </Form.Item>
              <Row gutter={3}>
                <Col xs={24} xl={8}>
                  <Form.Item
                    className="input"
                    label="Adult"
                    name="adult"
                    rules={[{ required: true, message: 'Please specify' }]}
                  >
                    <Select showSearch placeholder="0 Adult" disabled={!hasPermission}>
                      {createoptions(PASSENGERT_TYPE.ADULT, 15)}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} xl={8}>
                  <Form.Item
                    className="input"
                    label="Child"
                    name="child"
                    rules={[{ required: true, message: 'Please specify' }]}
                  >
                    <Select showSearch placeholder="0 Child" disabled={!hasPermission}>
                      {createoptions(PASSENGERT_TYPE.CHILD, 8)}
                    </Select>
                  </Form.Item>
                </Col>
                <Col xs={24} xl={8}>
                  <Form.Item
                    className="input"
                    label="Infant"
                    name="infant"
                    rules={[{ required: true, message: 'Please specify' }]}
                  >
                    <Select showSearch placeholder="0 Infant" disabled={!hasPermission}>
                      {createoptions(PASSENGERT_TYPE.INFANT, 4)}
                    </Select>
                  </Form.Item>
                </Col>
              </Row>
              <Form.Item
                className="input"
                label="Select date"
                name="date"
                rules={[{ required: true, message: 'Please pick a date' }]}
              >
                <DatePicker className="date-picker" format="YYYY-MM-DD" disabled={!hasPermission} />
              </Form.Item>
              <Form.Item className="input">
                <Button
                  disabled={loading || !hasPermission}
                  icon={<SearchOutlined />}
                  block
                  htmlType="submit"
                  size="large"
                  onClick={handleSearch}
                >
                  Search
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Tabs.TabPane>
        <Tabs.TabPane tab="Waybill" key={TAB_KEYS.WaybillTab}>
          <WaybillForm />
        </Tabs.TabPane>
      </Tabs>

      {/* We only want to show this is a search has been done */}
      {searchParams && <SearchResultView />}
    </div>
  );
};
