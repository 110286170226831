export enum USER_ROLE {
  User = 'role:user',
  Admin = 'role:admin',
  CustomerSupport = 'role:customer_support',
  SystemAdminstrator = 'role:system_admin',
  Operations = 'role:operations',
  FleetManagement = 'role:fleet_managment',
  Manager = 'role:manager',
  Auditor = 'role:auditor',
  ITSpecialist = 'role:it_specialist',
}

export enum PERMISSIONS {
  WriteUser = 'write_user',
  ReadUser = 'read_user',
  DeleteUser = 'delete_user',
  ReadTransactions = 'read_transaction',
  WriteTransactions = 'write_transaction',
  DeleteTransactions = 'delete_transaction',
  WriteTicket = 'write_ticket',
  ReadTicket = 'read_ticket',
  DeleteTicket = 'delete_ticket',
  CancelTicket = 'cancel_ticket',
  ReadWaybill = 'read_waybill',
  WriteWaybill = 'write_waybill',
  DeleteWaybill = 'delete_waybill',
  TransferWaybill = 'transfer_waybill',
  CancelWaybill = 'cancel_waybill',
  AssignWaybill = 'assign_waybill',
  ViewReservation = 'view_reservation',
  WriteReservation = 'write_reservation',
  DeleteReservation = 'delete_reservation',
  ReadPassenger = 'read_passenger',
  WritePassenger = 'write_passenger',
  DeletePassenger = 'delete_passenger',
  TransferPassenger = 'transfer_passenger',
  WriteDiscount = 'write_discount',
  ReadDiscount = 'read_discount',
  DeleteDiscount = 'delete_discount',
  WriteDestination = 'write_destination',
  ReadDestination = 'read_destination',
  DeleteDestination = 'delete_destination',
  WriteTrip = 'write_trip',
  ReadTrip = 'read_trip',
  DeleteTrip = 'delete_trip',
  CloseTrip = 'close_trip',
  OpenTrip = 'open_trip',
  WriteSeat = 'write_seat',
  ReadSeat = 'read_seat',
  DeleteSeat = 'delete_seat',
  WriteTripConfig = 'write_tripconfig',
  ReadTripConfig = 'read_tripconfig',
  DeleteTripConfig = 'delete_tripconfig',
  WriteExpense = 'write_expense',
  ReadExpense = 'read_expense',
  DeleteExpense = 'delete_expense',
  WriteVehicle = 'write_vehicle',
  ReadVehicle = 'read_vehicle',
  DeleteVehicle = 'delete_vehicle',
  WriteVehicleClass = 'write_vehicleclass',
  ReadVehicleClass = 'read_vehicleclass',
  DeleteVehicleClass = 'delete_vehicleclass',
  WriteRoute = 'write_route',
  ReadRoute = 'read_route',
  DeleteRoute = 'delete_route',
  WriteRouteFare = 'write_routefare',
  ReadRouteFare = 'write_routefare',
  WriteRouteFareOverrides = 'write_routefare_overrides',
  ReadRouteFareOverrides = 'write_routefare_overrides',
  WriteRouteGroup = 'write_routegroup',
  ReadRouteGroup = 'read_routegroup',
  DeleteRouteGroup = 'delete_routegroup',
  WriteTripSchedule = 'write_tripschedule',
  ReadTripSchedule = 'write_tripschedule',
}

export const RoleNameMapping = {
  [USER_ROLE.User]: 'User',
  [USER_ROLE.Admin]: 'Administrator',
  [USER_ROLE.CustomerSupport]: 'Customer Support',
  [USER_ROLE.Operations]: 'Operations',
  [USER_ROLE.SystemAdminstrator]: 'System/IT Specialist',
  [USER_ROLE.FleetManagement]: 'Fleet Management',
  [USER_ROLE.Manager]: 'Manager',
  [USER_ROLE.Auditor]: 'Auditor',
  [USER_ROLE.ITSpecialist]: 'IT Specialist',
};

export const RoleColorMapping = {
  [USER_ROLE.User]: 'magenta',
  [USER_ROLE.Admin]: 'red',
  [USER_ROLE.CustomerSupport]: 'volcano',
  [USER_ROLE.Operations]: 'orange',
  [USER_ROLE.SystemAdminstrator]: 'gold',
  [USER_ROLE.FleetManagement]: 'lime',
  [USER_ROLE.Manager]: 'green',
  [USER_ROLE.Auditor]: 'cyan',
  [USER_ROLE.ITSpecialist]: 'blue',
};

export interface IUserDeprecated {
  id: number;
  public_id: string;
  first_name: string;
  last_name: string;
  email: string;
  phone: string;
  organization_id: number;
  created_at: string;
  updated_at: string;
}

export interface IUser {
  id: number;
  publicId?: string;
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  driversLicenseNumber?: string;
  organizationId?: number;
  createdAt?: string;
  updatedAt?: string;
  roles: USER_ROLE[];
  permissions: PERMISSIONS[];
}

export interface IUserBranch {
  id?: number;
  user_id: number;
  branch_id: number;
}
