import { useEffect, useState } from 'react';
import { Button, Table, Card, Popconfirm, message, Space } from 'antd';
import { PlusOutlined, EditOutlined, DeleteOutlined, EyeOutlined } from '@ant-design/icons';
import { ColumnsType } from 'antd/lib/table';
import 'src/screens/users/styles/userManagement.styles.less';
import { UserModal } from 'src/screens/users/user-modal/UserModal';
import { usePage } from 'src/components/page/Page';
import { IUser, USER_ROLE, PERMISSIONS } from 'src/types/user.type';
import { getRoleTags } from 'src/screens/users/UserUtils';
import { PBACContainer } from 'src/components/RBAC/RBACContainer';
import { useDeleteUser } from 'src/hooks/useDeleteUser';
import { useAuth } from 'src/providers/auth-provider/AuthProvider';
import { Link } from 'react-router-dom';
import { SearchBar } from 'src/components/search-bar/SearchBar';
import { User, useFindUsersQuery } from 'src/graphql/queries/find-users';
import { useGeRolesQuery } from 'src/graphql/queries/get-roles';

export const Users = () => {
  const page = usePage();
  const [deleteUser] = useDeleteUser();
  const { user: authUser } = useAuth();

  const [showUserModal, setShowUserModal] = useState<boolean>(false);
  const [selectedUser, setSelectedUser] = useState<IUser>();
  const [searchField, setSearchField] = useState<string>('');
  const [users, setUsers] = useState<User[] | undefined>(undefined);

  const { data, loading, refetch } = useFindUsersQuery(searchField);
  const { data: rolesData, loading: rolesLoading } = useGeRolesQuery();

  const mapUser = (user: User): IUser => ({
    id: Number(user.id),
    firstName: user.firstName,
    lastName: user.lastName,
    email: user.email,
    phone: user.phone,
    roles: user.roles as USER_ROLE[],
    permissions: user.permissions as PERMISSIONS[],
    organizationId: undefined,
    createdAt: undefined,
    updatedAt: undefined,
  });

  useEffect(() => {
    if (data) {
      setUsers(data.findUsers || []);
    }
  }, [searchField, data]);

  useEffect(() => {
    page.setTitle('Users');
    page.setSubTitle('All Users');
  }, []);

  const closeUserModal = () => {
    setSelectedUser(undefined);
    setShowUserModal(false);
  };

  const showEditUserModal = (id: number) => {
    const usersList = users?.map(mapUser);
    const user = usersList?.find((u: IUser) => u.id === Number(id));

    if (user) {
      setSelectedUser(user);
      setShowUserModal(true);
    }
  };

  const onConfirmDeleteUser = async (id: number) => {
    const deleted = await deleteUser({ params: { id } });

    if (deleted) {
      message.success('User successfully deleted');
      setUsers((prev) => prev?.filter((user) => Number(user.id) !== id));
      await refetch();
    }
  };

  const onUserActionComplete = async () => {
    await refetch();
    setSelectedUser(undefined);
    setShowUserModal(false);
  };

  const columns: ColumnsType<User> = [
    {
      title: 'Name',
      dataIndex: 'firstName',
      key: 'first_name',
      render: (text, { lastName }) => <p>{`${text} ${lastName}`}</p>,
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Phone Number',
      dataIndex: 'phone',
      key: 'phone',
    },
    {
      title: 'Roles',
      dataIndex: 'roles',
      key: 'roles',
      render: (roles: USER_ROLE[]) => getRoleTags(roles),
    },
    {
      title: 'Action',
      dataIndex: 'id',
      key: 'id',
      width: '150px',
      render: (id: number) => {
        return (
          <Space split="|">
            <Link to={`${id}`}>
              <Button
                className="more-btn"
                icon={<EyeOutlined />}
              />
            </Link>
            <Button
              onClick={() => showEditUserModal(id)}
              className="more-btn"
              href="#"
              icon={<EditOutlined />}
              disabled={
                !authUser.permissions?.includes(PERMISSIONS.WriteUser)
              }
            />

            <Popconfirm
              title="Are you sure to delete this user?"
              onConfirm={() => onConfirmDeleteUser(id)}
              placement="topRight"
            >
              <Button
                className="more-btn"
                href="#"
                icon={<DeleteOutlined />}
                disabled={
                  !authUser.permissions?.includes(PERMISSIONS.DeleteUser)
                }
              />
            </Popconfirm>
          </Space>
        );
      },
      fixed: 'right',
    },
  ];

  const handleUserSearch = async (e: string) => {
    setSearchField(e);
  };

  return (
    <div className="user-page-container">
      <PBACContainer permissions={[PERMISSIONS.ReadUser, PERMISSIONS.WriteUser, PERMISSIONS.DeleteUser]}>
        <Card
          bordered={false}
          extra={
            <div className="extra-container">
              <div key="search-container" className="search-container">
                <SearchBar
                  className="user-search"
                  width={400}
                  placeHolder="Search users..."
                  loading={loading}
                  onSearch={(e: string) => handleUserSearch(e)}
                />
              </div>
              <Button
                key="add-new-user"
                type="primary"
                icon={<PlusOutlined />}
                disabled={!(authUser.permissions?.includes(PERMISSIONS.WriteUser))}
                onClick={() => setShowUserModal(true)}
              >
                Add New
              </Button>
            </div>
          }
        >
          <Table
            loading={loading || rolesLoading}
            rowKey={(user: User) => user.id}
            columns={columns}
            dataSource={users || []}
            size="small"
            bordered
            pagination={{ pageSize: 100, total: users?.length ?? 100 }}
            scroll={{ x: 500 }}
          />
        </Card>

        {showUserModal && (
          <UserModal
            show={showUserModal}
            systemRoles={rolesData}
            onClose={closeUserModal}
            onComplete={onUserActionComplete}
            defaultUser={selectedUser}
          />
        )}
      </PBACContainer>
    </div>
  );
};
