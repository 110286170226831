import { QueryResult, gql } from '@apollo/client';
import { useQueryErrorNotification } from 'src/graphql/hooks/useQueryErrorNotification';
import { RolesDataQuery, RolesDataQueryVariables } from 'src/graphql/queries/__generated__/get-roles.generated';

const GET_ROLES_QUERY = gql`
  query RolesData {
    rolesData {
      key
      value
    }
  }
`;

export const useGeRolesQuery = (): QueryResult<RolesDataQuery, RolesDataQueryVariables> =>
  useQueryErrorNotification<RolesDataQuery, RolesDataQueryVariables>(GET_ROLES_QUERY);
