import { MutationTuple, gql } from '@apollo/client';
import { useMutationErrorNotification } from 'src/graphql/hooks/useMutationErrorNotification';
import {
  LoginUserMutation,
  LoginUserMutationVariables,
} from 'src/graphql/mutations/__generated__/login-user.generated';

const LOGIN_USER_MUTATION = gql`
  mutation LoginUser($input: LoginUserInput!) {
    loginUser(input: $input) {
      accessToken
    }
  }
`;

export const useLoginUserMutation = (): MutationTuple<LoginUserMutation, LoginUserMutationVariables> =>
  useMutationErrorNotification<LoginUserMutation, LoginUserMutationVariables>(LOGIN_USER_MUTATION);
