import { resources } from 'src/services/api/resources';
import { IUser, USER_ROLE, PERMISSIONS } from 'src/types/user.type';
import { useUpdate } from 'src/hooks/shared/useUpdate';
import { api } from 'src/services/api';

export interface UseUpdateUserParams {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  organizationId: number;
  password: string;
  driversLicenseNumber?: string;
  roles?: USER_ROLE[];
  permissions: PERMISSIONS[];
  userId: number;
  branchIds?: number[];
}

export const useUpdateUser = () =>
  useUpdate<IUser, UseUpdateUserParams>(async (options) => {
    const payload = { ...options?.params, noTransform: true };
    const { data } = await api.patch<IUser>(`${resources.userUrl}/${options?.params?.userId}`, payload);
    return data;
  });
